import React, {useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import {API_URL} from "../../../context/connection-string";
import {toast} from "react-toastify";
import { Chart } from "react-google-charts";
import {DashboardPageTitle} from "./dashboard-page-title";
import {currencyConverter} from "../../../context/function";



function DashboardAccounting({loginData}) {
    const [isLoading, setIsLoading] = useState(true);
    const [departmentData, setDepartmentData] = useState([["Department", "Employee"]])
    const [inventoryRequestData, setInventoryRequestData] = useState([["Request Status", "Count"]])
    const [maintenanceRequestData, setMaintenanceRequestData] = useState([["Maintenance Status", "Count"]])
    const [walletData, setWalletData] = useState({credit:0, outstanding:0})
    const [paymentData, setPaymentData] = useState([]);
    const [paymentHistory, setPaymentHistory] = useState([]);

    const fetchRecord = async () => {
        await axios.get(`${API_URL}report/accounting-dashboard/${loginData[0].branch_id}`, loginData[0].token)
            .then(res => {
                const employee_list = res.data.employee_list;

                //DEPARTMENT CHART START
                const departments = res.data.department_list;
                let department_data = [
                    ...departmentData
                ];
                if (departments.length > 0) {
                    departments.map(r => {
                        return department_data.push([r.department_name, employee_list.filter(e=>e.department_id===r.department_id).length])
                    })
                }
                setDepartmentData(department_data)
                //DEPARTMENT CHART END

                //INVENTORY CHART START
                const inventory_request = res.data.inventory_request_list;
                let inventory_data = [
                    ...inventoryRequestData
                ]
                if (inventory_request.length > 0) {
                    const unique_payment_status = [...new Set(inventory_request.map(item => item.payment_status))];
                    unique_payment_status.map(r => {
                        return inventory_data.push([r, inventory_request.filter(e=>e.payment_status===r).length])
                    })
                }
                setInventoryRequestData(inventory_data)
                //INVENTORY CHART END

                //INVENTORY CHART START
                const maintenance = res.data.maintenance_list;
                let maintenance_data = [
                    ...maintenanceRequestData
                ]
                if (maintenance.length > 0) {
                    const unique_maintenance_status = [...new Set(maintenance.map(item => item.status))];
                    unique_maintenance_status.map(r => {
                        return maintenance_data.push([r, maintenance.filter(e=>e.status===r).length])
                    })
                }
                setMaintenanceRequestData(maintenance_data)
                //INVENTORY CHART END

                //WALLET CHART START
                setWalletData({
                    credit: res.data.wallet_balance[0].wallet_balance, outstanding: res.data.outstanding[0].wallet_outstanding
                })
                //WALLET CHART END

                //PAYMENT START
                const payment = res.data.credit;
                let payment_record = [];
                let payment_header = ["Month"]
                if (payment.length > 0) {
                    const unique_payment_method = [...new Set(payment.map(item => item.payment_method))];
                    const unique_payment_month = [...new Set(payment.map(item => item.payment_month_name))];
                    const unique_payment_year = [...new Set(payment.map(item => item.payment_year))];
                    unique_payment_method.map(r=>payment_header.push(r))

                    unique_payment_year.map(year => {
                        unique_payment_month.map(month => {
                            if (payment.filter(w=>w.payment_year === year && w.payment_month_name === month).length > 0) {
                                let payment_item = [`${month} ${year}`]
                                unique_payment_method.map(p => {
                                    const payment_method_total = payment.filter(e=>e.payment_method === p && e.payment_year === year && e.payment_month_name === month)
                                    const sum = payment_method_total.reduce((accumulator, object) => {
                                        return accumulator + object.amount_paid;
                                    }, 0);
                                    payment_item.push(sum)
                                })

                                return payment_record.push(payment_item)
                            }
                        })
                    })

                    const last_6_payment_record = payment_record.slice(-6)
                    last_6_payment_record.unshift(payment_header)
                    setPaymentData(last_6_payment_record)
                }
                //PAYMENT END

                //PAYMENT HISTORY START
                const payment_history = res.data.debit;
                let payment_history_record = [];
                let payment_history_header = ["Month"]
                if (payment_history.length > 0) {
                    const unique_payment_for = [...new Set(payment_history.map(item => item.payment_for))];
                    const unique_payment_history_month = [...new Set(payment_history.map(item => item.payment_month_name))];
                    const unique_payment_history_year = [...new Set(payment_history.map(item => item.payment_year))];
                    unique_payment_for.map(r=>payment_history_header.push(r))

                    unique_payment_history_year.map(year => {
                        unique_payment_history_month.map(month => {
                            if (payment_history.filter(w=>w.payment_year === year && w.payment_month_name === month).length > 0) {
                                let payment_item = [`${month} ${year}`]
                                unique_payment_for.map(p => {
                                    const payment_for_total = payment_history.filter(e=>e.payment_for === p && e.payment_year === year && e.payment_month_name === month)
                                    const sum = payment_for_total.reduce((accumulator, object) => {
                                        return accumulator + object.payment_amount;
                                    }, 0);
                                    payment_item.push(sum)
                                })

                                return payment_history_record.push(payment_item)
                            }
                        })
                    })

                    const last_6_payment_history_record = payment_history_record.slice(-6)
                    last_6_payment_history_record.unshift(payment_history_header)
                    setPaymentHistory(last_6_payment_history_record)
                }
                //PAYMENT HISTORY END

                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    useState(()=>{
        fetchRecord()
    },[])

    return isLoading ? <Loader /> : (
        <>
            <DashboardPageTitle title="Accounting Department" />

            <div className="row g-5 g-xl-12 pt-5">
                <div className="col-xl-6">
                    <span className="card bg-info hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3"
                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                          fill="currentColor"/>
                                    <path
                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                        fill="currentColor"/>
                                </svg>
                            </span>
                            <div className="text-white fw-semibold fs-3x lh-1 ls-n2 pt-5">{currencyConverter(walletData.credit)}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Guest Credit Balance</div>
                        </div>
                    </span>
                </div>
                <div className="col-xl-6">
                    <span className="card bg-danger hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3"
                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                          fill="currentColor"/>
                                    <path
                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                        fill="currentColor"/>
                                </svg>
                            </span>
                            <div className="text-white fw-semibold fs-3x lh-1 ls-n2 pt-5">{currencyConverter(walletData.outstanding)}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Guest Outstanding</div>
                        </div>
                    </span>
                </div>

                {
                    departmentData.length > 1 &&
                        <div className="col-xl-4">
                            <Chart
                                chartType="PieChart"
                                data={departmentData}
                                options={{title: "Staff Distribution", is3D: true}}
                                width={"100%"}
                                height={"400px"}
                            />
                        </div>
                }

                {
                    inventoryRequestData.length > 1 &&
                        <div className="col-xl-4">
                            <Chart
                                chartType="PieChart"
                                data={inventoryRequestData}
                                options={{title: "Inventory Request Payment Status", is3D: true}}
                                width={"100%"}
                                height={"400px"}
                            />
                        </div>
                }

                {
                    maintenanceRequestData.length > 1 &&
                        <div className="col-xl-4">
                            <Chart
                                chartType="PieChart"
                                data={maintenanceRequestData}
                                options={{title: "Maintenance Request Status", is3D: true}}
                                width={"100%"}
                                height={"400px"}
                            />
                        </div>
                }

                {
                    paymentData.length > 0 &&
                        <div className="col-xl-12">
                            <Chart
                                chartType="Bar"
                                data={paymentData}
                                options={{chart:{title: "Wallet Payment History"}}}
                                width={"100%"}
                                height={"400px"}
                            />
                        </div>
                }

                {
                    paymentHistory.length > 0 &&
                        <div className="col-xl-12">
                            <Chart
                                chartType="Bar"
                                data={paymentHistory}
                                options={{chart:{title: "Guest Payment Deductions"}}}
                                width={"100%"}
                                height={"400px"}
                            />
                        </div>
                }


            </div>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(DashboardAccounting);
