import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import { API_URL } from '../../../context/connection-string';
import Select from "react-select";
import {decryptData, encryptData} from "../../../context/function";

function CreateUser(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [staff, setStaff] = useState([]);
    const [employee, setEmployee] = useState([]);
    const controller = new AbortController();

    const [formData, setFormData] = useState({
        staff: "",
        staff2: "",
        employee_id: "",
        employee_name: "",
        email_address: "",
        password: "",
        password_new: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const getData = async (data = []) => {
        const { signal } = controller.signal;
        await axios.get(`${API_URL}report/select/data`, login?.token).then((res) => {
            if (res.data.message === "success"){
                let staffRows = [];
                res.data.Staff.map((row) => {
                    staffRows.push({value: row.employee_id, label: row.employee_name, password: row.password, email_address: row.email_address})
                });
                setStaff(prevState => [prevState, ...staffRows])
            }else{
                setStaff([])
            }
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })
    }

    useEffect(() => {
        getData()

        return () => {
            controller.abort()
        }
    }, [])


    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (formData.email_address === ""){
            toast.error("Please Enter Email Address")
            return false;
        }

        if (formData.password_new === ""){
            toast.error("Please Enter Password")
            return false;
        }

        const SendData = {
            ...formData,
            password: encryptData(formData.password_new)
        }

        await axios.patch(`${API_URL}human-resources/employee/update/login`, SendData, login?.token)
            .then((res) => {
                if (res.data.message === "success") {
                    toast.success("User Login Updated successfully");
                    setStaff([])
                    setFormData({
                        staff: "",
                        staff2: "",
                        employee_id: "",
                        employee_name: "",
                        email_address: "",
                        password: "",
                        password_new: "",
                        inserted_by: props.loginData[0]?.employee_id,
                    })
                    getData();
                    setOpenModal(false)
                }
            }).catch((e) => { toast.error(e.response.data) })
    }

    const onChangeStaff = (e) => {
        setOpenModal(false)
        const val = e.value;
        setFormData({
            ...formData,
            staff: val,
            employee_id: val,
            employee_name: e.label,
            email_address: e.email_address,
            password: e.password,
            staff2: e,
        })
    }

    const onGetEmployee = () => {
        if (formData.staff !== ""){
            setOpenModal(true)
        }else{
            toast.error("Please select staff to fetch record")
        }

    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Create User Login"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Settings', link: '/' },
                                    { name: 'Create User Login', link: '/settings/create-user' }]}
                />
                <div className="row">
                    <div className="col-xl-12">
                        <div className=' mb-5 no-printing'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-10 pb-3">
                                        <div className="form-group ">
                                            <label htmlFor="staff">Select Staff</label>
                                            <Select
                                                id="staff"
                                                name="staff"
                                                value={formData.staff2}
                                                onChange={onChangeStaff}
                                                options={staff}
                                                placeholder="Select Staff"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 pb-3">
                                        <div className="form-group ">
                                            <label htmlFor="button">&nbsp;</label>
                                            <button
                                                className='btn btn-md btn-primary no-printing mb-3 w-100'
                                                onClick={onGetEmployee} >
                                                Get Employee Record
                                            </button>
                                        </div>
                                    </div>

                                    {
                                        isLoading ?
                                            <Loader />
                                            :
                                            openModal ?
                                            employee.length > 0 ?
                                                <div>
                                                    Staff record Form
                                                </div>
                                                :
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-12 pb-3 mb-3">
                                                            <div className="form-group">
                                                                <label htmlFor="employee_name">Employee Name</label>
                                                                <input type="text" id="employee_name" className="form-control" disabled value={formData.employee_name} onChange={onChange} required />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12 pb-3 mb-5">
                                                            <div className="form-group">
                                                                <label htmlFor="email_address">Email Address</label>
                                                                <input type="text" id="email_address" className="form-control" value={formData.email_address} onChange={onChange} required />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12 pb-3 mb-5">
                                                            <div className="form-group">
                                                                <label htmlFor="password">Password</label>
                                                                <p className="text-danger">Current Password: {decryptData(formData.password)}</p>
                                                                <input type="text" id="password_new" className="form-control" onChange={onChange} required />
                                                            </div>
                                                        </div>

                                                        <button type="submit" onClick={onSubmit} className="btn btn-lg btn-block btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                                : <></>

                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(CreateUser);
