import React from 'react'
import { Link } from 'react-router-dom';
import GeneralToolbar from '../../../component/include/general-toolbar';
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";


const PageCard = ({ title, link, svg }) => {
    return (
        <div className='card pt-5 ps-5 mb-5 mb-xl-8 bg-dark text-white'>
            <Link to={`${link}`}>
                <div className="d-flex align-items-center mb-9 me-2">
                    <div className="symbol symbol-40px me-3">
                        <div className="symbol-label bg-light">
                            <span className="svg-icon svg-icon-1 svg-icon-dark">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="currentColor" />
                                    <path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="currentColor" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="fs-1 text-white fw-bolder lh-2">{title}</div>
                        <div className="fs-7 text-gray-600 fw-bold">{link}</div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
export default function ReportDashboard() {


    return (
        <div>
            <GeneralToolbar title="Report Dashboard"
                            pages={[{ name: 'Dashboard', link: '/' }, { name: 'Report', link: '/' },
                                { name: 'Dashboard', link: '/report/dashboard' }]}
            />
            <div className="row">
                <div className="col-xl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8 theme-dark-bg-body" style={{ backgroundColor: '#CBD4F4' }}>
                        <div className="card-body d-flex flex-column">
                            <div className="row">
                                <div className="col-6  ">
                                    <PageCard title={"Payroll Schedule"} link={"/report/payroll-schedule"} />
                                </div>

                                <div className="col-6  ">
                                    <PageCard title={"Bank Schedule"} link={"/report/bank-schedule"} />
                                </div>

                                <div className="col-6  ">
                                    <PageCard title={"PAYE Schedule"} link={"/report/paye-schedule"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"WHT Schedule"} link={"/report/wht-schedule"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Pension Schedule"} link={"/report/pension-schedule"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"NHF Schedule"} link={"/report/nhf-schedule"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"NHIS Schedule"} link={"/report/nhis-schedule"} />
                                </div>


                                <div className='col-6'>
                                    <PageCard title={"NSITF Schedule"} link={"/report/nsitf-schedule"} />
                                </div>


                                <div className='col-6'>
                                    <PageCard title={"Payroll Journal Voucher"} link={"/report/payroll-journal-voucher"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Payroll Report By Paypoint"} link={"/report/payroll-report-by-paypointe"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Allowance & Deduction Report"} link={"/report/allowance-and-deduction-report"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Staff Verification List"} link={"/report/staff-verification-list"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Custom Report"} link={"/report/custom-report"} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
