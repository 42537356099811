import React from "react";
import {API_URL} from "../../../../context/connection-string";

export default function PartnerForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            {
                props.partner_id !== "" &&
                <div className='d-flex justify-content-center mb-5'>
                    <img src={`${API_URL}public/partner/${props.value.image_path}`} width={"300px"}  alt=""/>
                </div>
            }
            <div className="row">
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="partner_name">Partner Name</label>
                    <input
                        type="text"
                        id={"partner_name"}
                        onChange={props.onChange}
                        value={props.value.partner_name}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="partner_url">Partner URL</label>
                    <input
                        type="text"
                        id={"partner_url"}
                        onChange={props.onChange}
                        value={props.value.partner_url}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="image_path">Upload Image</label>
                        <input type="file" id="image_path" name={"image_path"} accept="image/*" className="form-control" onChange={props.onChange}/>
                    </div>
                </div>

                {
                    props.isFormLoading ?
                        <button id="kt_docs_formvalidation_text_submit" type="button" className="btn btn-primary">
                            <span> Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"/> </span>
                        </button>
                        :
                        <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
                }
            </div>
        </form>
    )
}
