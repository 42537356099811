import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import { currencyConverter, formatDateAndTime, showConfirm } from '../../../context/function';
import RunAllowancesForm from './run-allowances-form';
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";

function RunAllowances(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [recordList, setRecordList] = useState([]);
    const [ledgerAccountsList, setLedgerAccountsList] = useState([])

    const columns = ["S/N", "EMPLOYEE", "POST TYPE", "ACCOUNT", "START DATE", "END DATE", "FREQUENCY", "AMOUNT"];

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })
    const [progress, setProgress] = useState({
        percentage: 0,
        variant: 'danger'
    });

    const getData = async () => {
        try {
            await axios.get(`${API_URL}human-resources/ledger-accounts/list`, login?.token).then(async (res) => {
                setLedgerAccountsList(res.data)
                await getLedgerAccounts(res.data);
            })

        } catch (e) {
            toast.error("error")
        } finally {
            setIsLoading(false)
        }
    }

    const getLedgerAccounts = async (acct = []) => {
        await axios.get(`${API_URL}human-resources/payroll/entry/active/list`, login?.token)
            .then((res) => {
                if (res.data.length > 0) {
                    setRecordList(res.data)
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.employee_name + ` (${x.employee_id})`,
                            x.post_type,
                            acct?.filter(j => parseInt(j.ledger_id) === parseInt(x.ledger_account))[0]?.account_number,
                            formatDateAndTime(x.start_date, "month_and_year"),
                            formatDateAndTime(x.end_date, "month_and_year"),
                            x.frequency,
                            currencyConverter(x.amount)
                        ])
                    })
                    setTableData(rows)
                } else {
                    toast.warning("no allowances/deductions data")
                }
                setIsLoading(false);
            }).catch((e) => {
                console.log(e)
                toast.error("error getting allowances")
            }).finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        checkPrevRun(e)
    }


    const onOpenModal = (status) => {
        setIsSubmitting(false);
        setFormData({
            ...formData,
            month_id: "",
        })
        setProgress({
            ...progress,
            percentage: 0,
            variant: 'danger'
        });
        setOpenModal(status);
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        toast.info("please wait...")
        setProgress({
            ...progress,
            percentage: 0,
            variant: 'danger'
        });

        recordList.map((item, index) => {
            const sendData = {
                ...item,
                salary_id: item.allowance_id,
                ledger_account: ledgerAccountsList?.filter(j => parseInt(j.ledger_id) === parseInt(item.ledger_account))[0]?.account_number,
                allowance_amount: item.post_type === 'Allowance' ? item.amount : 0,
                deduction_amount: item.post_type === "Deduction" ? item.amount : 0,
                salary_date: formData.month_id,
                inserted_by: formData.inserted_by,
            }

            axios.post(`${API_URL}human-resources/payroll/add/allowance`, sendData, login?.token)
                .then((result) => {
                    if (result.data.message === "success") {
                        const percentage = (index + 1) / recordList.length * 100;
                        let variant = "";
                        if (percentage <= 25)
                            variant = 'danger'
                        else if (percentage > 25 && percentage <= 50)
                            variant = 'warning'
                        else if (percentage > 50 && percentage <= 75)
                            variant = 'info'
                        else
                            variant = 'success'

                        setProgress({
                            ...progress,
                            percentage: Math.round(percentage),
                            variant: variant
                        })

                        if (index + 1 === recordList.length) {
                            toast.success("Allowance and deduction saved successful");
                            setOpenModal(false);
                            getLedgerAccounts(ledgerAccountsList);
                        }
                    }
                })
                .catch((error) => {
                    toast.error("Please check your connection and try again!");
                });
        });
    }

    const checkPrevRun = (e) => {
        setIsSubmitting(true);
        axios.post(`${API_URL}human-resources/payroll/salary/check_run`, { salary_date: e.target.value }, login?.token)
            .then(result => {
                if (result.data.length > 0) {
                    toast.error(`Salary already ran for ${formatDateAndTime(e.target.value, "month_and_year")}`);
                    setFormData({
                        ...formData,
                        month_id: ""
                    })
                    setIsSubmitting(false);
                } else {
                    setIsSubmitting(false);
                }
            })
            .catch(err => {
                console.log('NETWORK ERROR', err)
            })
    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Allowances and Deductions"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/' },
                    { name: 'Allowances and Deductions', link: '/human-resources/manage-allowance-and-deduction' }]}
                    button={<button className="btn btn-primary" onClick={() => { onOpenModal(true); setIsSubmitting(false); setFormData({ ...formData, month_id: "" }) }}>RUN</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Manage Allowances and Deductions">
                    <RunAllowancesForm
                        values={formData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                        progress={progress}
                    />
                </BootstrapModal>
                <div className="row">
                    <PayrollSidebar/>
                    <div className="col-xl-10">
                        <ReportTable title={"Allowances and Deductions"} columns={columns} data={tableData} height={"700px"} />
                    </div>
                </div>

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(RunAllowances);
