import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import {useState} from "react";
import {stateFromHTML} from "draft-js-import-html";
import draftToHtml from 'draftjs-to-html';

export default function TextEditor(props) {
    const contentBlocks = stateFromHTML(props.description);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentBlocks !== "" ? contentBlocks : ""));

    const onEditorStateChange = (eS) => {
        setEditorState(eS)
        props.result(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    return (
        <Editor
            className="form-control form-control-solid"
            id="description"
            onEditorStateChange={onEditorStateChange}
            editorClassName="form-control form-control-solid"
            editorState={editorState}
        />
    )
}
