import React from 'react'

export default function SalaryLedgerAccountForm(props) {
    return (
        <div>

            <form onSubmit={props.onSubmit}>
                <div className="row">
                    <div className="col-md-12 pb-3">
                        <div className="form-group">
                            <label htmlFor="account_number">A&D Code</label>
                            <input type="number" id="account_number" className="form-control" value={props.values.account_number} onChange={props.onChange} required />
                        </div>
                    </div>
                    <div className="col-md-12 pb-3">
                        <div className="form-group">
                            <label htmlFor="description">A&D Description</label>
                            <input type="text" id="description"
                                className="form-control" value={props.values.description} onChange={props.onChange} required />
                        </div>
                    </div>

                    <div className="col-md-12 pb-3">
                        <div className="form-group">
                            <label htmlFor="account_type">A&D Account</label>
                            <select className='form-control' id="account_type" onChange={props.onChange} value={props.values.account_type} required  >
                                <option value={""}> -select account type- </option>
                                <option value={"Heading"}>Heading</option>
                                <option value={"Posting"}>Posting</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12 pb-3">
                        <div className="form-group">
                            <label htmlFor="balance_or_income">Mapping </label>
                            <select className='form-control' id="balance_or_income" onChange={props.onChange} value={props.values.balance_or_income} required  >
                                <option value={""}> -select- </option>
                                <option value={"Balance Sheet"}>Balance Sheet</option>
                                <option value={"Income Statement"}>Income Statement</option>
                            </select>
                        </div>
                    </div>


                    <button type="submit" className="btn btn-lg btn-block btn-primary" disabled={props.isSubmitting} >
                        {props.isSubmitting ? <span> <i className='fa fa-spin fa-spinner' /> &nbsp;Please wait...</span> : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
}
