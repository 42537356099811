import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicRoutes from "./route/public-route/public-route";
import PrivateRoutes from "./route/private-route/private-route";
import {setLoginDetails} from "./action/set-action-details";
function App(props) {

    return (
        <div className="App">
            <Router>
                {props.loginData.length < 1 ? <PublicRoutes /> : <PrivateRoutes />}
            </Router>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ToastContainer />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setOnLoginDetails: (p) => {
            dispatch(setLoginDetails(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);