import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import { API_URL } from '../../../context/connection-string';
import {currencyConverter, formatDateAndTime, moneyFormat} from '../../../context/function';
import ReportSidebar from "../../../component/report-sidebar/report-sidebar";
import DataTable from "../../../component/data-table/data-table";
import NHFDataTable from "./data-table/nhf-data-table";

function NhfSchedule(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const header = ["S/N", "Contributors NO", "Surname",  "OtherNames", "Staff ID", "Mobile Number", "Email Address", "Basic Salary (₦)",  "Contributors Amount (₦)"];
    const [reportData, setReportData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })
    // let total = 0;

    const getData = async () => {

        await axios.get(`${API_URL}human-resources/report/salary-months`, login?.token).then((res) => {
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }
    const  showTable = () => {

        try {
            return reportData.filter(e => e.NHF > 0).map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.nhf_number}</td>
                        <td className="text-xs font-weight-bold">{item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.OtherNames}</td>
                        <td className="text-xs font-weight-bold">{item.staff_id}</td>
                        <td className="text-xs font-weight-bold">{item.phone_number}</td>
                        <td className="text-xs font-weight-bold">{item.email_address}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.basic)}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.NHF)}</td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    const  getTotal = () => {
        let total_amount = 0;
        reportData.filter(e => e.NHF > 0).map((item, index) => {
            total_amount += item.NHF;
        });
        return total_amount;
    }


    const getSalaryReport = async (salary_month) => {

        if (salary_month === "") {
        } else {
            setIsSubmitting(true)
            await axios.get(`${API_URL}report/payroll/schedule?salary_month=${salary_month}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        setReportData(res.data)
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        getSalaryReport(val)
    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="NHF Schedule"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Report', link: '/report/dashboard' },
                                    { name: 'NHF Schedule', link: '/report/nhf-schedule' }]}
                />

                <div className="row">
                    <ReportSidebar/>
                    <div className="col-xl-10">
                        <div className=' mb-5'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <input type="month" id="month_id"
                                                   className="form-control"
                                                   value={formData.month_id}
                                                   onChange={onChange}
                                                   max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                   required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <div className="">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <h3 className="card-title">NHF Schedule Report</h3>
                                                <NHFDataTable header={header} date={formatDateAndTime(formData.month_id, 'month_and_year')} caption={`TOTAL AMOUNT: ${currencyConverter(getTotal() ?? 0)}`} body={showTable()} title={`NHF Schedule Report For ${formatDateAndTime(formData.month_id, 'month_and_year')}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(NhfSchedule);
