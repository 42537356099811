import React from "react";
import {API_URL} from "../../../../context/connection-string";

export default function SliderForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            {
                props.slider_id !== "" &&
                <div className='d-flex justify-content-center mb-5'>
                    <img src={`${API_URL}public/slider/${props.value.image_path}`} width={"300px"}  alt=""/>
                </div>
            }
            <div className="row">
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="title">Slider Title</label>
                    <input
                        type="text"
                        id={"title"}
                        onChange={props.onChange}
                        value={props.value.title}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="caption">Slider Caption</label>
                    <input
                        type="text"
                        id={"caption"}
                        onChange={props.onChange}
                        value={props.value.caption}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="btn_name">Button Name</label>
                    <input
                        type="text"
                        id={"btn_name"}
                        onChange={props.onChange}
                        value={props.value.btn_name}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="btn_link">Button link</label>
                    <input
                        type="text"
                        id={"btn_link"}
                        onChange={props.onChange}
                        value={props.value.btn_link}
                        className={"form-control"}
                        placeholder={""}
                    />
                </div>
                <div className="form-group col-md-6 mb-4">
                    <label htmlFor="status">Status</label>
                    <select
                        id={"status"}
                        onChange={props.onChange}
                        value={props.value.status}
                        className={"form-control"}
                    >
                        <option value=""> Select Status...</option>
                        <option value="1"> Publish</option>
                        <option value="0"> Draft</option>
                    </select>
                </div>
                <div className="col-md-6 pb-3">
                    <div className="form-group">
                        <label htmlFor="image_path">Upload Image (1920 x 1280)</label>
                        <input type="file" id="image_path" name={"image_path"} accept="image/*" className="form-control" onChange={props.onChange}/>
                    </div>
                </div>

                {
                    props.isFormLoading ?
                        <button id="kt_docs_formvalidation_text_submit" type="button" className="btn btn-primary">
                            <span> Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"/> </span>
                        </button>
                        :
                        <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
                }
            </div>
        </form>
    )
}
