import React from 'react'

export default function TitlesForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3 mb-5">
                    <div className="form-group">
                        <label htmlFor="title">Title Name</label>
                        <input type="text" id="title" className="form-control" value={props.values.title} onChange={props.onChange} required />
                    </div>
                </div>

                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
