import React, {useState} from "react";
import {connect} from "react-redux";
import axios from "axios";
import Loader from "../../../component/loader/loader";
import {API_URL} from "../../../context/connection-string";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {Alert} from "@mui/material";
import {currencyConverter, formatDateAndTime} from "../../../context/function";
import AllRoomBookingCalenderView from "../../front-desk/booking/room-calender-view/all-room-booking-calender-view";
import {DashboardPageTitle} from "./dashboard-page-title";
import { Chart } from "react-google-charts";

function DashboardAdmin({loginData}) {
    const [isLoading, setIsLoading] = useState(true);
    const [bookingList, setBookingList] = useState([])
    const [bookingActionList, setBookingActionList] = useState([])
    const [branchList, setBranchList] = useState([]);
    const [statistic, setStatistic] = useState([]);
    const [departmentData, setDepartmentData] = useState([["Department", "Employee"]])
    const [inventoryRequestData, setInventoryRequestData] = useState([["Request Status", "Count"]])
    const [maintenanceRequestData, setMaintenanceRequestData] = useState([["Maintenance Status", "Count"]])
    const [walletData, setWalletData] = useState({credit:0, outstanding:0})
    const [paymentData, setPaymentData] = useState([]);
    const [paymentHistory, setPaymentHistory] = useState([]);

    const fetchRecord = async () => {
        await axios.get(`${API_URL}report/admin-dashboard`, loginData[0].token)
            .then(res => {
                const branch_list = res.data.branch_list
                setBranchList(branch_list)
                setBookingList(res.data.booking_list)
                setBookingActionList(res.data.booking_action)

                if (branch_list.length > 0) {
                    let statistic_data = [];
                    branch_list.map(r => {
                        statistic_data.push({branch_id: r.branch_id, type:'total_room', count: res.data.room_list.filter(e=>e.branch_id===r.branch_id).length})
                        statistic_data.push({branch_id: r.branch_id, type:'available_room', count: res.data.available_room_list.filter(e=>e.branch_id===r.branch_id).length})
                        statistic_data.push({branch_id: r.branch_id, type:'checked_in', count: res.data.booking_action.filter(e=>e.branch_id===r.branch_id && e.status==='checked_in').length})
                        statistic_data.push({branch_id: r.branch_id, type:'checked_out', count: res.data.booking_action.filter(e=>e.branch_id===r.branch_id && e.status==='checked_out').length})
                        statistic_data.push({branch_id: r.branch_id, type:'cancelled', count: res.data.booking_action.filter(e=>e.branch_id===r.branch_id && e.status==='cancelled').length})
                        statistic_data.push({branch_id: r.branch_id, type:'booked', count: res.data.booking_action.filter(e=>e.branch_id===r.branch_id && e.status==='booked').length})
                    })
                    setStatistic(statistic_data)
                    console.log(statistic_data)
                }

                //CHARTS
                const employee_list = res.data.employee_list;

                //DEPARTMENT CHART START
                const departments = res.data.department_list;
                let department_data = [
                    ...departmentData
                ];
                if (departments.length > 0) {
                    departments.map(r => {
                        return department_data.push([r.department_name, employee_list.filter(e=>e.department_id===r.department_id).length])
                    })
                }
                setDepartmentData(department_data)
                //DEPARTMENT CHART END

                //INVENTORY CHART START
                const inventory_request = res.data.inventory_request_list;
                let inventory_data = [
                    ...inventoryRequestData
                ]
                if (inventory_request.length > 0) {
                    const unique_payment_status = [...new Set(inventory_request.map(item => item.payment_status))];
                    unique_payment_status.map(r => {
                        return inventory_data.push([r, inventory_request.filter(e=>e.payment_status===r).length])
                    })
                }
                setInventoryRequestData(inventory_data)
                //INVENTORY CHART END

                //INVENTORY CHART START
                const maintenance = res.data.maintenance_list;
                let maintenance_data = [
                    ...maintenanceRequestData
                ]
                if (maintenance.length > 0) {
                    const unique_maintenance_status = [...new Set(maintenance.map(item => item.status))];
                    unique_maintenance_status.map(r => {
                        return maintenance_data.push([r, maintenance.filter(e=>e.status===r).length])
                    })
                }
                setMaintenanceRequestData(maintenance_data)
                //INVENTORY CHART END

                //WALLET CHART START
                setWalletData({
                    credit: res.data.wallet_balance[0].wallet_balance, outstanding: res.data.outstanding[0].wallet_outstanding
                })
                //WALLET CHART END

                //PAYMENT START
                const payment = res.data.credit;
                let payment_record = [];
                let payment_header = ["Month"]
                if (payment.length > 0) {
                    const unique_payment_method = [...new Set(payment.map(item => item.payment_method))];
                    const unique_payment_month = [...new Set(payment.map(item => item.payment_month_name))];
                    const unique_payment_year = [...new Set(payment.map(item => item.payment_year))];
                    unique_payment_method.map(r=>payment_header.push(r))

                    unique_payment_year.map(year => {
                        unique_payment_month.map(month => {
                            if (payment.filter(w=>w.payment_year === year && w.payment_month_name === month).length > 0) {
                                let payment_item = [`${month} ${year}`]
                                unique_payment_method.map(p => {
                                    const payment_method_total = payment.filter(e=>e.payment_method === p && e.payment_year === year && e.payment_month_name === month)
                                    const sum = payment_method_total.reduce((accumulator, object) => {
                                        return accumulator + object.amount_paid;
                                    }, 0);
                                    payment_item.push(sum)
                                })

                                return payment_record.push(payment_item)
                            }
                        })
                    })

                    const last_6_payment_record = payment_record.slice(-6)
                    last_6_payment_record.unshift(payment_header)
                    setPaymentData(last_6_payment_record)
                }
                //PAYMENT END

                //PAYMENT HISTORY START
                const payment_history = res.data.debit;
                let payment_history_record = [];
                let payment_history_header = ["Month"]
                if (payment_history.length > 0) {
                    const unique_payment_for = [...new Set(payment_history.map(item => item.payment_for))];
                    const unique_payment_history_month = [...new Set(payment_history.map(item => item.payment_month_name))];
                    const unique_payment_history_year = [...new Set(payment_history.map(item => item.payment_year))];
                    unique_payment_for.map(r=>payment_history_header.push(r))

                    unique_payment_history_year.map(year => {
                        unique_payment_history_month.map(month => {
                            if (payment_history.filter(w=>w.payment_year === year && w.payment_month_name === month).length > 0) {
                                let payment_item = [`${month} ${year}`]
                                unique_payment_for.map(p => {
                                    const payment_for_total = payment_history.filter(e=>e.payment_for === p && e.payment_year === year && e.payment_month_name === month)
                                    const sum = payment_for_total.reduce((accumulator, object) => {
                                        return accumulator + object.payment_amount;
                                    }, 0);
                                    payment_item.push(sum)
                                })

                                return payment_history_record.push(payment_item)
                            }
                        })
                    })

                    const last_6_payment_history_record = payment_history_record.slice(-6)
                    last_6_payment_history_record.unshift(payment_history_header)
                    setPaymentHistory(last_6_payment_history_record)
                }
                //PAYMENT HISTORY END

                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)

            })
    }

    useState(()=>{
        fetchRecord()
    },[])

    return isLoading ? <Loader /> : (
        <>
            <DashboardPageTitle title="Admin" />
            <div className="row g-5 g-xl-12 pt-5">
                <div className="col-xl-6">
                    <span className="card bg-info hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3"
                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                          fill="currentColor"/>
                                    <path
                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                        fill="currentColor"/>
                                </svg>
                            </span>
                            <div className="text-white fw-semibold fs-3x lh-1 ls-n2 pt-5">{currencyConverter(walletData.credit)}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Guest Credit Balance</div>
                        </div>
                    </span>
                </div>
                <div className="col-xl-6">
                    <span className="card bg-danger hoverable card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3"
                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                          fill="currentColor"/>
                                    <path
                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                        fill="currentColor"/>
                                </svg>
                            </span>
                            <div className="text-white fw-semibold fs-3x lh-1 ls-n2 pt-5">{currencyConverter(walletData.outstanding)}</div>
                            <div className="text-white fw-bold fs-2 mb-2">Guest Outstanding</div>
                        </div>
                    </span>
                </div>
            </div>
            {
                branchList.length > 0 &&
                    branchList.map((branch, index) => {
                        return (
                            <div key={index} className="row g-5 g-xl-12 pt-5">
                                <h4>{branch.branch_name}</h4>
                                <div className="col-xl-4">
                                    <span className="card bg-success hoverable card-xl-stretch mb-xl-8">
                                        <div className="card-body">
                                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3"
                                                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                                                          fill="currentColor"/>
                                                                    <path
                                                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                                                        fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                            <div className="text-white fw-bold fs-2 mb-2 mt-5">{statistic.filter(r=>r.branch_id===branch.branch_id && r.type==='total_room')[0].count}</div>
                                            <div className="text-white fw-bold fs-2 mb-2">Total Number of Rooms</div>
                                        </div>
                                    </span>
                                </div>
                                <div className="col-xl-4">
                                    <span className="card bg-primary hoverable card-xl-stretch mb-xl-8">
                                        <div className="card-body">
                                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3"
                                                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                                                          fill="currentColor"/>
                                                                    <path
                                                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                                                        fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                            <div className="text-white fw-bold fs-2 mb-2 mt-5">{statistic.filter(r=>r.branch_id===branch.branch_id && r.type==='available_room')[0].count}</div>
                                            <div className="text-white fw-bold fs-2 mb-2">Available Rooms</div>
                                        </div>
                                    </span>
                                </div>
                                <div className="col-xl-4">
                                    <span className="card bg-info hoverable card-xl-stretch mb-5 mb-xl-8">
                                        <div className="card-body">
                                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3"
                                                                          d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z"
                                                                          fill="currentColor"/>
                                                                    <path
                                                                        d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                                                        fill="currentColor"/>
                                                                </svg>
                                                            </span>
                                            <div className="text-white fw-bold fs-2 mb-2 mt-5">{statistic.filter(r=>r.branch_id===branch.branch_id && r.type==='checked_in')[0].count}</div>
                                            <div className="text-white fw-bold fs-2 mb-2">Checked In</div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        )
                    })
            }


            <div className="row g-5 g-lg-12 ">
                <div className="col-xl-12 col-xxl-12 mb-5 mb-xl-10">
                    <div className="card card-flush h-xl-100">
                        <div className="card-header py-7">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 ">
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                                       href="#latest_booking">Latest Booking</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                       href="#checked_in">Checked In</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                       href="#checked_out">Checked Out</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                                       href="#all_booking">All Bookings</a>
                                </li>
                            </ul>
                            <div className="card-toolbar">
                                <Link to="/front-desk/add-booking" className="btn btn-primary">Add Booking</Link>
                            </div>
                        </div>
                        <div className="card-body pt-1 tab-content">
                            <div className="separator separator-dashed my-5" />
                            <div className="tab-pane fade show active" id="latest_booking">
                                {
                                    bookingList.filter(i=>i.status === 'booked').length > 0 ?
                                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                            <tr>
                                                <th>Booking Code</th>
                                                <th>Branch</th>
                                                <th>Guest Name</th>
                                                <th>Check-In</th>
                                                <th>Check-Out</th>
                                                <th>Rooms</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bookingList.filter(r=>r.status === 'booked').map((r,index) => {
                                                    if (index < 11)
                                                        return (
                                                            <tr key={index}>
                                                                <td className="min-w-175px">
                                                                    <div className="position-relative ps-6 pe-3 py-2">
                                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info"/>
                                                                        <Link to={`/front-desk/booking/${r.booking_code}`} className="mb-1 text-dark text-hover-primary fw-bold">{r.booking_code}</Link>
                                                                        <div className="fs-7 text-muted fw-bold">Booked on {formatDateAndTime(r.inserted_date, 'date')}</div>
                                                                    </div>
                                                                </td>
                                                                <td><div className="d-flex gap-2 mb-2">{branchList.filter(e=>e.branch_id === r.branch_id)[0].branch_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{r.guest_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_in_date, 'date')}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_out_date, 'date')}</div></td>

                                                                <td className="min-w-125px">
                                                                    <div className="symbol-group symbol-hover mb-1">
                                                                        {
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).length > 0 &&
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).map((o,d) => {
                                                                                return (
                                                                                    <span key={d}>{o.type_name} ({o.room_number}){bookingActionList.filter(f=>f.booking_code===r.booking_code).length !== d+1 && `, `}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        : <Alert  severity="info">No Latest Booking</Alert>
                                }
                            </div>
                            <div className="tab-pane fade" id="checked_in">
                                {
                                    bookingList.filter(i=>i.status === 'checked_in').length > 0 ?
                                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                            <tr>
                                                <th>Booking Code</th>
                                                <th>Branch</th>
                                                <th>Guest Name</th>
                                                <th>Check-In</th>
                                                <th>Check-Out</th>
                                                <th>Rooms</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bookingList.filter(r=>r.status === 'checked_in').map((r,index) => {
                                                    if (index < 11)
                                                        return (
                                                            <tr key={index}>
                                                                <td className="min-w-175px">
                                                                    <div className="position-relative ps-6 pe-3 py-2">
                                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info"/>
                                                                        <Link to={`/front-desk/booking/${r.booking_code}`} className="mb-1 text-dark text-hover-primary fw-bold">{r.booking_code}</Link>
                                                                        <div className="fs-7 text-muted fw-bold">Booked on {formatDateAndTime(r.inserted_date, 'date')}</div>
                                                                    </div>
                                                                </td>
                                                                <td><div className="d-flex gap-2 mb-2">{branchList.filter(e=>e.branch_id === r.branch_id)[0].branch_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{r.guest_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_in_date, 'date')}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_out_date, 'date')}</div></td>

                                                                <td className="min-w-125px">
                                                                    <div className="symbol-group symbol-hover mb-1">
                                                                        {
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).length > 0 &&
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).map((o,d) => {
                                                                                return (
                                                                                    <span key={d}>{o.type_name} ({o.room_number}){bookingActionList.filter(f=>f.booking_code===r.booking_code).length !== d+1 && `, `}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        : <Alert  severity="info">No Latest Booking</Alert>
                                }
                            </div>
                            <div className="tab-pane fade" id="checked_out">
                                {
                                    bookingList.filter(i=>i.status === 'checked_out').length > 0 ?
                                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                            <tr>
                                                <th>Booking Code</th>
                                                <th>Branch</th>
                                                <th>Guest Name</th>
                                                <th>Check-In</th>
                                                <th>Check-Out</th>
                                                <th>Rooms</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bookingList.filter(r=>r.status === 'checked_out').map((r,index) => {
                                                    if (index < 11)
                                                        return (
                                                            <tr key={index}>
                                                                <td className="min-w-175px">
                                                                    <div className="position-relative ps-6 pe-3 py-2">
                                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info"/>
                                                                        <Link to={`/front-desk/booking/${r.booking_code}`} className="mb-1 text-dark text-hover-primary fw-bold">{r.booking_code}</Link>
                                                                        <div className="fs-7 text-muted fw-bold">Booked on {formatDateAndTime(r.inserted_date, 'date')}</div>
                                                                    </div>
                                                                </td>
                                                                <td><div className="d-flex gap-2 mb-2">{branchList.filter(e=>e.branch_id === r.branch_id)[0].branch_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{r.guest_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_in_date, 'date')}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_out_date, 'date')}</div></td>

                                                                <td className="min-w-125px">
                                                                    <div className="symbol-group symbol-hover mb-1">
                                                                        {
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).length > 0 &&
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).map((o,d) => {
                                                                                return (
                                                                                    <span key={d}>{o.type_name} ({o.room_number}){bookingActionList.filter(f=>f.booking_code===r.booking_code).length !== d+1 && `, `}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        : <Alert  severity="info">No Latest Booking</Alert>
                                }
                            </div>
                            <div className="tab-pane fade" id="all_booking">
                                {
                                    bookingList.length > 0 ?
                                        <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3">
                                            <thead>
                                            <tr>
                                                <th>Booking Code</th>
                                                <th>Branch</th>
                                                <th>Guest Name</th>
                                                <th>Check-In</th>
                                                <th>Check-Out</th>
                                                <th>Rooms</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                bookingList.map((r,index) => {
                                                    if (index < 11)
                                                        return (
                                                            <tr key={index}>
                                                                <td className="min-w-175px">
                                                                    <div className="position-relative ps-6 pe-3 py-2">
                                                                        <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-info"/>
                                                                        <Link to={`/front-desk/booking/${r.booking_code}`} className="mb-1 text-dark text-hover-primary fw-bold">{r.booking_code}</Link>
                                                                        <div className="fs-7 text-muted fw-bold">Booked on {formatDateAndTime(r.inserted_date, 'date')}</div>
                                                                    </div>
                                                                </td>
                                                                <td><div className="d-flex gap-2 mb-2">{branchList.filter(e=>e.branch_id === r.branch_id)[0].branch_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{r.guest_name}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_in_date, 'date')}</div></td>
                                                                <td><div className="d-flex gap-2 mb-2">{formatDateAndTime(r.check_out_date, 'date')}</div></td>

                                                                <td className="min-w-125px">
                                                                    <div className="symbol-group symbol-hover mb-1">
                                                                        {
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).length > 0 &&
                                                                            bookingActionList.filter(f=>f.booking_code===r.booking_code).map((o,d) => {
                                                                                return (
                                                                                    <span key={d}>{o.type_name} ({o.room_number}){bookingActionList.filter(f=>f.booking_code===r.booking_code).length !== d+1 && `, `}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                })
                                            }

                                            </tbody>
                                        </table>
                                        : <Alert  severity="info">No Latest Booking</Alert>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row g-5 g-xl-12 pt-10">
                {
                    departmentData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={departmentData}
                            options={{title: "Staff Distribution", is3D: true}}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

                {
                    inventoryRequestData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={inventoryRequestData}
                            options={{title: "Inventory Request Payment Status", is3D: true}}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

                {
                    maintenanceRequestData.length > 1 &&
                    <div className="col-xl-4">
                        <Chart
                            chartType="PieChart"
                            data={maintenanceRequestData}
                            options={{title: "Maintenance Request Status", is3D: true}}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

                {
                    paymentData.length > 0 &&
                    <div className="col-xl-12">
                        <Chart
                            chartType="Bar"
                            data={paymentData}
                            options={{chart:{title: "Wallet Payment History"}}}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }

                {
                    paymentHistory.length > 0 &&
                    <div className="col-xl-12">
                        <Chart
                            chartType="Bar"
                            data={paymentHistory}
                            options={{chart:{title: "Guest Payment Deductions"}}}
                            width={"100%"}
                            height={"400px"}
                        />
                    </div>
                }
            </div>

            <div className="row g-5 g-xl-12 pt-10">
                <AllRoomBookingCalenderView booking={bookingList} />
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails
    };
};


export default connect(mapStateToProps, null)(DashboardAdmin);
