import React from "react";
import { Route, Routes } from "react-router-dom";
import GeneralFooter from "../../component/include/general-footer";
import GeneralHeader from "../../component/include/general-header";
import Dashboard from "../../module/general/dashboard";
import ManageDepartments from "../../module/human-resources/departments/manage-departments";
import ManageEmployees from "../../module/human-resources/employees/manage-employees";
import ManageTitles from "../../module/human-resources/job-titles/manage-titles";
import BranchManagement from "../../module/settings/branch/branch-management";

import UserGroup from "../../module/settings/permission/user-group/user-group";
import UserGroupPermission from "../../module/settings/permission/user-group-permission/user-group-permission";
import SalarySettings from "../../module/human-resources/salary-settings/salary-settings";
import SalaryLedgerAccount from "../../module/human-resources/salary-ledger-account/ledger-account";
import AllowancesAndDeductions from "../../module/human-resources/allowances-and-deductions/allowances-and-deductions";
import PayRoll from "../../module/human-resources/payroll/payroll";
import RunAllowances from "../../module/human-resources/run-allowances/run-allowances";
import RunSalary from "../../module/human-resources/run-salary/run-salary";

import SalaryReport from "../../module/human-resources/salary-report/report";

import WebsiteManagementSettings
    from "../../module/settings/website-management/website-management-settings/website-management-settings";
import BookingGeneralReport from "../../module/report/booking-report/booking-report";
import RoomTypeReport from "../../module/report/room-type-report/room-type-report";
import PayRegister from "../../module/human-resources/salary-report/pay-register/pay-register";
import EmployeeDetails from "../../module/human-resources/employees/employee-details/employee-details";
import ManageIncomeEmoluments from "../../module/settings/income-and-emoluments/indome-and-emoluments";
import ManageDivisions from "../../module/human-resources/divisions/manage-divisions";
import ManageSalaryType from "../../module/human-resources/salary-type/manage-salary-type";
import ManagePension from "../../module/human-resources/pension-settings/manage-pension";
import ManageDesignation from "../../module/human-resources/designation/manage-designation";
import ReportDashboard from "../../module/report/salary-report/salary-report";
import PayrollSchedule from "../../module/report/salary-report/payroll-schedule";
import BankSchedule from "../../module/report/salary-report/bank-schedule";
import PayeSchedule from "../../module/report/salary-report/paye_schedule";
import WhtSchedule from "../../module/report/salary-report/wht_schedule";
import PensionSchedule from "../../module/report/salary-report/pension_schedule";
import NhfSchedule from "../../module/report/salary-report/nhf_schedule";
import NhisSchedule from "../../module/report/salary-report/nhis_schedule";
import NsitfSchedule from "../../module/report/salary-report/nsitf_schedule";
import PayrollPayPointReport from "../../module/report/salary-report/payroll-paypoint-report";
import AllowancesAndDeductionsReport from "../../module/report/salary-report/allowance-and-deduction-report";
import PayrollJournalVoucher from "../../module/report/salary-report/payroll-journal-voucher";
import CustomReport from "../../module/report/salary-report/custom-report";
import MonthlyStaffPayroll from "../../module/report/salary-report/payroll-paypoint-report-by-staff";
import MultiplePaySlip from "../../module/human-resources/salary-report/multiple-pay-slip";
import StaffVerificationList from "../../module/report/salary-report/staff-verification-list";
import CreateUser from "../../module/settings/user/create-user-login";
import AdminSalarySettings from "../../module/settings/salary-settings/salary-settings";
import StaffMonthlySalary from "../../module/report/salary-report/monthly-salary-report";



export default function PrivateRoutes() {
    return (
        <div className="d-flex flex-column flex-root">
            {/*begin::Page*/}
            <div className="page d-flex flex-row flex-column-fluid">
                {/*begin::Wrapper*/}
                <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                    {/*begin::Header*/}
                    <GeneralHeader />
                    {/*end::Header*/}
                    {/*begin::Container*/}
                    <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
                        {/*begin::Post*/}
                        <div className="flex-row-fluid" id="kt_content">
                            <Routes>
                                <Route exact path={"/"} element={<Dashboard />} />
                            </Routes>



                            {/*begin::Human Resources*/}
                            <Routes>
                                <Route exact path={"/employee-management/employees"} element={<ManageEmployees />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/employee-management/:employee_id"} element={<EmployeeDetails />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/employee-management/departments"} element={<ManageDepartments />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/employee-management/job-title"} element={<ManageTitles />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/manage-allowance-and-deduction"} element={<AllowancesAndDeductions />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/run-allowance-and-deduction"} element={<RunAllowances />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/run-salary"} element={<RunSalary />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/salary-report"} element={<SalaryReport />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/multiple-pay-slip"} element={<MultiplePaySlip />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/payroll"} element={<PayRoll />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/salary-settings"} element={<SalarySettings />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/ledger-accounts"} element={<SalaryLedgerAccount />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/human-resources/pay-register"} element={<PayRegister />} />
                            </Routes>
                            {/*end::Human Resources*/}


                            {/*begin::Report*/}
                            <Routes>
                                <Route exact path={"/report/reservation-report"} element={""} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/booking-report"} element={<BookingGeneralReport />} />
                            </Routes>

                            <Routes>
                                <Route exact path={"/report/room-type-report"} element={<RoomTypeReport />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/room-report"} element={""} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/room-status-report"} element={""} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/dashboard"} element={<ReportDashboard/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/payroll-schedule"} element={<PayrollSchedule/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/bank-schedule"} element={<BankSchedule/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/paye-schedule"} element={<PayeSchedule/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/wht-schedule"} element={<WhtSchedule/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/pension-schedule"} element={<PensionSchedule/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/nhf-schedule"} element={<NhfSchedule/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/nhis-schedule"} element={<NhisSchedule/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/nsitf-schedule"} element={<NsitfSchedule/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/payroll-journal-voucher"} element={<PayrollJournalVoucher/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/payroll-report-by-paypointe"} element={<PayrollPayPointReport/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/allowance-and-deduction-report"} element={<AllowancesAndDeductionsReport/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/custom-report"} element={<CustomReport/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/payroll/paypoint/report-by-staff"} element={<StaffMonthlySalary/>} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/report/staff-verification-list"} element={<StaffVerificationList/>} />
                            </Routes>
                            {/*end::Report*/}

                            {/*begin::Settings*/}
                            <Routes>
                                <Route exact path={"/settings/branch-management"} element={<BranchManagement />} />
                            </Routes>

                            <Routes>
                                <Route exact path={"/settings/website-management"} element={<WebsiteManagementSettings />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/settings/user-group"} element={<UserGroup />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/settings/user-permission"} element={<UserGroupPermission />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/settings/create-user"} element={<CreateUser />} />
                            </Routes>
                            <Routes>
                                <Route exact path={"/settings/admin-salary-settings"} element={<AdminSalarySettings />} />
                            </Routes>
                            {/*end::Settings*/}

                            {/*begin::User Account*/}
                            <Routes>
                                <Route exact path={"/staff/profile"} element={""} />
                            </Routes>
                            {/*end::User Account*/}

                            <Routes>
                                <Route exact path={"/human-resources/income-and-emoluments"} element={<ManageIncomeEmoluments/>} />
                            </Routes>

                            <Routes>
                                <Route exact path={"/employee-management/divisions"} element={<ManageDivisions/>} />
                            </Routes>

                            <Routes>
                                <Route exact path={"/employee-management/salary-type"} element={<ManageSalaryType/>} />
                            </Routes>

                            <Routes>
                                <Route exact path={"/employee-management/pension-scheme"} element={<ManagePension/>} />
                            </Routes>

                            <Routes>
                                <Route exact path={"/employee-management/designations"} element={<ManageDesignation/>} />
                            </Routes>

                        </div>
                        {/*end::Post*/}
                    </div>

                    {/*end::Container*/}
                    {/*begin::Footer*/}
                    <GeneralFooter />
                    {/*end::Container*/}
                </div>
            </div>
        </div>
    );
}
