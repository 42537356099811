import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {GeneralDetailsReducer, LoginDetailsReducer, PermissionDetailsReducer} from "./reducer-details";
import {HOTEL_CODE} from "../context/connection-string";

const rootReducer = combineReducers({
    LoginDetails: LoginDetailsReducer,
    PermissionDetails: PermissionDetailsReducer,
    GeneralDetails: GeneralDetailsReducer
});
const persistConfig = { key: HOTEL_CODE, storage};

export default persistReducer(persistConfig, rootReducer);
