import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import { CheckNumbers, formatDateAndTime } from '../../../context/function';
import SalarySettingsForm from './settings-form';
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";

function SalarySettings(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState([])
    const columns = ["Basic (%)", "Housing (%)", "Transport (%)", "Fringe (%)", "Medical (%)", "Wardrobe (%)", "	Payee (%)", "Inserted By", "Inserted Date", "Action"];

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        setting_id: "", basic: 0, housing: 0, transport: 0, fringe: 0, medical: 0, wardrobe: 0, payee: 0,
        inserted_by: props.loginData[0]?.employee_id, total: 0
    })

    const getData = async () => {
        await getSettings();
    }

    const getSettings = async () => {
        await axios.get(`${API_URL}human-resources/salary-settings/list`, login?.token)
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            // i + 1,
                            // x.setting_id.toString().padStart(4, "0"),
                            x.basic,
                            x.housing,
                            x.transport,
                            x.fringe,
                            x.medical,
                            x.wardrobe,
                            x.payee,
                            x.inserted_by_name + ` (${x.inserted_by})`,
                            formatDateAndTime(x.inserted_date, "date"),
                            <button className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        setting_id: x.setting_id,
                                        basic: x.basic,
                                        housing: x.housing,
                                        transport: x.transport,
                                        fringe: x.fringe,
                                        medical: x.medical,
                                        wardrobe: x.wardrobe,
                                        payee: x.payee,                                    })
                                    setOpenModal(true);
                                }} >
                                <i className='fa fa-edit' />&nbsp; Edit
                            </button>
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error("error getting settings")
            })
    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = parseFloat(e.target.value);
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
    }
    const onSum = (e) => {
        const val = parseInt(e.target.value);
        if (e.target.value.toString().trim() !== "") {
            setTotal(parseFloat(formData.basic) + parseFloat(formData.housing) + parseFloat(formData.transport) + parseFloat(formData.medical) + parseFloat(formData.wardrobe) + parseFloat(formData.fringe) + parseFloat(formData.payee))
        }
    }

    const onOpenModal = (status) => {
        setIsSubmitting(false);
        setFormData({
            ...formData,
            setting_id: "", basic: 0, housing: 0, transport: 0, fringe: 0, medical: 0, wardrobe: 0, payee: 0, total: 0
        })
        setTotal(0);
        setOpenModal(status);
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        if ((parseFloat(total)) < 100) {
            toast.error("total must amount to 100%");
            return false;
        }

        if ((parseFloat(total)) > 100) {
            toast.error("total must amount not exceed 100%");
            return false;
        }

        setIsSubmitting(true);
        toast.info("please wait...")
        if (formData.setting_id === "") {
            await axios.post(`${API_URL}human-resources/salary-settings/add`, formData, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        getSettings();
                        toast.success("settings added successfully");
                        onOpenModal(false);
                    } else if (res.data.message === "exists") {
                        toast.warning("settings already exists");
                    } else {

                    }
                    setIsSubmitting(false)
                }).catch(() => {
                    setIsSubmitting(false);
                    toast.error(e.response.data)
                })
        } else {
            await axios.patch(`${API_URL}human-resources/salary-settings/update`, formData, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        getSettings();
                        toast.success("settings added successfully");
                        onOpenModal(false);
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error(e.response.data)
                })
        }
    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Salary Settings"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/' },
                    { name: 'Salary Settings', link: '/human-resources/salary-settings' }]}

                    button={tableData.length === 0 &&
                        <button className="btn btn-primary" onClick={() => { onOpenModal(true); setIsSubmitting(false) }}>Add Settings</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Manage Salary Settings">
                    <SalarySettingsForm
                        values={formData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                        total={total}
                        onSum={onSum}
                    />
                </BootstrapModal>
                <div className="row">
                    <PayrollSidebar/>
                    <div className="col-xl-9">
                        <ReportTable title={"Salary Settings"} columns={columns} data={tableData} height={"700px"} />
                    </div>
                </div>

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(SalarySettings);
