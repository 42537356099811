import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import {connect} from "react-redux";
import Loader from "../../../../component/loader/loader";
import {useNavigate} from "react-router";
function AllRoomBookingCalenderView ({booking}) {
    const [loading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [events, setEvents] = useState([])
    useEffect (() => {
        let event = [];
        if (booking.length > 0) {
            booking.map(r => {
                let status = '';
                if (r.status === 'booked')
                    status = '#378006'
                else if (r.status === 'missed')
                    status = '#f60340'
                else if (r.status === 'cancelled')
                    status = '#dc758f'
                else if (r.status === 'checked_in')
                    status = '#224df5'
                else
                    status = '#fc7303'
                event.push({
                    title: `${r.guest_name} : ${r.booking_code}`,
                    start: r.check_in_date,
                    end: r.check_out_date,
                    backgroundColor: status
                })
            })
            setEvents(event)
            setIsLoading(false)
        }

    },[])

    const onEventClick = (arg) => {
        const booking_code = arg.split(':')[1]
        navigate(`/front-desk/booking/${booking_code.trim()}`)
    }
    return loading ? <Loader /> : (
        <>
            <div className="row">
                <h1>Booking Calender</h1>
                <div className="btn-group">
                    <button style={{backgroundColor: '#378006'}} className="btn btn-group" >Booked</button>
                    <button style={{backgroundColor: '#f60340'}} className="btn btn-group" >Missed</button>
                    <button style={{backgroundColor: '#dc758f'}} className="btn btn-group" >Cancelled</button>
                    <button style={{backgroundColor: '#224df5'}} className="btn btn-group" >Checked In</button>
                    <button style={{backgroundColor: '#fc7303'}} className="btn btn-group" >Checked Out</button>
                </div>
            </div>
            <FullCalendar
                themeSystem="Simplex"
                plugins={[dayGridPlugin ]}
                events={events}
                eventClick={(arg)=>onEventClick(arg.event.title)}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        permissionData: state.PermissionDetails
    };
};

export default connect(mapStateToProps, null)(AllRoomBookingCalenderView);
