import {connect} from "react-redux";
import GeneralToolbar from "../../../../component/include/general-toolbar";
import ReportTable from "../../../../component/table/report-table";
import BootstrapModal from "../../../../component/modal/bootstrap-modal";
import {useEffect, useState} from "react";
import { toast } from "react-toastify";
import {API_URL} from "../../../../context/connection-string";
import axios from "axios";
import Loader from "../../../../component/loader/loader";
import {formatDateAndTime, loginValidation} from "../../../../context/function";
import UserGroupForm from "./user-group-form";

function UserGroup(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openMembersModal, setOpenMembersModal] = useState(false);
    const token = props.loginData[0].token;
    const initialValue = {
        group_name: "",
        inserted_by: props.loginData[0].employee_id,
        group_id: "",
    };
    const [formData, setFormData] = useState(initialValue);
    //For User Group List
    const [employeeList, setEmployeeList] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [selectedGroupID, setSelectedGroupID] = useState(0);
    //For User List Permission
    const permission_columns = ["S/N", "Employee Name", "Branch", "Employee Status", "Decision", "Added By", "Added Date", "Action"];
    const [permissionTableData, setPermissionTableData] = useState([]);

    const fetchData = async () => {
        await axios.get(`${API_URL}settings/group/user/group/data`, token)
            .then(res => {
                if (res.data.message === 'success') {
                    setEmployeeList(res.data.employee)
                    setPermissionList(res.data.members)
                    setGroupList(res.data.data)
                } else {
                    toast.info("Something went wrong. Please try again!")
                }
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }
    const handleOnUpdateBtnClick = (r) => {
        setFormData({group_id: r.group_id, group_name: r.group_name, inserted_by: r.inserted_by});
        setOpenModal(true)
    }
    const onOpenModal = () => {
        setFormData(initialValue)
        setOpenModal(true);
    }
    const handleFormValueChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id] : e.target.value
        })
    }
    const onOpenMemberForm = (g) => {
        setSelectedGroupID(g)
        //FOR PERMISSION DATAs
        let perm_data = [];
        employeeList.length > 0 &&
        employeeList.map((r,i)  => {
            const perm_exist = permissionList.filter(p => p.employee_id === r.employee_id && p.group_id === g)
            const decision = perm_exist.length > 0 ? <span className={"badge text-success"}>added</span> : <span className={"badge text-danger"}>not added</span>
            const added_by = perm_exist.length > 0 ? perm_exist[0].inserted_name : '--';
            const added_date = perm_exist.length > 0 ? formatDateAndTime(perm_exist[0].inserted_date, 'date') : '--';
            const action = perm_exist.length > 0 ?
                <button title={"Remove"} onClick={()=>{permissionManagement(r.employee_id, g, 'remove')}} className={"btn btn-danger btn-sm"}><i className={"fa fa-trash"} /></button> :
                <button title={"Add"} onClick={()=>{permissionManagement(r.employee_id, g, 'add')}} className={"btn btn-success btn-sm"}><i className={"fa fa-plus"} /></button>
            perm_data.push([i+1, r.full_name, r.branch_name, r.status, decision, added_by, added_date, action])
        })
        setPermissionTableData(perm_data)

        setOpenMembersModal(true);
    }

    const permissionManagement = async (employee_id, group_id, action) => {
        const sendData = {group_id: group_id, employee_id: employee_id, inserted_by: initialValue.inserted_by}
        if (action === 'add') {
            await axios.post(`${API_URL}settings/group/member/add`, sendData, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Permission Added Successfully");
                        fetchData();
                    } else {
                        toast.error("Error Adding Permission. Please Try Again!")
                    }
                })
                .catch(e => {
                    toast.error(e.message)
                    toast.error(`${e.response.statusText}: ${e.response.data}`)
                })
        } else {
            await axios.delete(`${API_URL}settings/group/member/delete/${group_id}/${employee_id}`, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Permission Removed Successfully");
                        fetchData();
                    } else {
                        toast.success("Error Removing Permission. Please Try Again");
                    }
                })
        }
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        if (formData.group_name.trim() === "") {
            toast.error("Please Enter the Group Name");
            return false;
        }
        toast.info("Submitting, please wait...");
        if (formData.group_id === '') {
            await axios.post(`${API_URL}settings/group/add`, formData, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("User Group Added Successfully");
                        setOpenModal(false);
                        fetchData();
                    } else if (res.data.message === 'exits') {
                        toast.error("A group with a similar name already exist. Please check the name and try again!");
                    } else {
                        toast.error("Something went wrong while adding a new group. Please check your network and try again!");
                    }
                })
                .catch(e => {
                    toast.error(e.message)
                })
        } else {
            await axios.patch(`${API_URL}settings/group/update`, formData, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Group Updated Successfully");
                        setOpenModal(false);
                        fetchData();
                    } else {
                        toast.error("Something went wrong while updating the group. Please check your network and try again!");
                    }
                })
                .catch(e => {
                    toast.error(e.message)
                })
        }
    }

    useEffect(() => {
        if (loginValidation(window.location.pathname, props.permissionData) !== 'permitted')
            window.location.href = '/';

        fetchData()
    },[])

    useEffect(() => {
        if (selectedGroupID !== 0)
            onOpenMemberForm(selectedGroupID)
    }, [permissionList])

    return isLoading ? <Loader /> : (
        <>
            <GeneralToolbar title="User Group" pages={[{name: 'Dashboard', link: '/'}, {name: 'Settings', link: '/'}, {name: 'User Group', link: '/'}]} button={<button className="btn btn-primary" onClick={onOpenModal}>Add Group</button>} />
            <BootstrapModal
                openModal={openModal}
                closeModal={setOpenModal}
                size= "xl"
                title="Manage User Group">
                <UserGroupForm onSubmit={onFormSubmit} value={formData} onChange={handleFormValueChange} />
            </BootstrapModal>

            <BootstrapModal
                openModal={openMembersModal}
                closeModal={setOpenMembersModal}
                size= "xl"
                title="User Group Permission">
                <ReportTable title={"User Group Permission"} columns={permission_columns} data={permissionTableData} />
            </BootstrapModal>
            {/*begin::Table*/}
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <div className="col-md-12">
                            <table className="table table-stripped table-hover">
                                <thead>
                                <tr>
                                    <th>S/N</th>
                                    <th>Group Name</th>
                                    <th>Edit</th>
                                    <th>Members</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    groupList.length > 0 && groupList.map((r,i) => {
                                        return <tr key={i}>
                                            <td>{i+1}</td>
                                            <td>{r.group_name}</td>
                                            <td><button className={"btn btn-primary btn-sm"} onClick={() => {handleOnUpdateBtnClick(r)}}><i className={"fa fa-pencil"} /></button></td>
                                            <td><button className={"btn btn-primary btn-sm"} onClick={() => {onOpenMemberForm(r.group_id)}}><i className={"fa fa-users"} /></button></td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/*end::Table*/}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        permissionData: state.PermissionDetails
    };
};

export default connect(mapStateToProps, null)(UserGroup);
