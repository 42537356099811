import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import {API_URL, HOTEL_NAME} from '../../../context/connection-string';
import {
    COMPANY_PENSION_EMPLOYER_CODE,
    COMPANY_PFA, COMPANY_RECEIVING_ACCOUNT_NO,
    COMPANY_RECEIVING_BANK, COMPANY_SORT_CODE,
    currencyConverter,
    formatDateAndTime,
    moneyFormat
} from '../../../context/function';
import ReportSidebar from "../../../component/report-sidebar/report-sidebar";
import PensionDataTable from "./data-table/pension-data-table";
import "../../human-resources/salary-report/style.css"

function PensionSchedule(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const header1 = ["S/N", "Surname",  "OtherNames", "RSA PIN", "PFA", "Pensionable Emolument (₦)", "Employer Contribution (₦)",  "Arrears (₦)",  "Employee Contribution (₦)",  "Arrears (₦)", "Voluntary Contribution (₦)", "Total Contribution Payable (₦)"];
    const header = ["S/N", "Surname",  "OtherNames", "RSA PIN", "Pensionable Emolument (₦)", "Employer Contribution (₦)",  "Arrears (₦)",  "Employee Contribution (₦)",  "Arrears (₦)", "Voluntary Contribution (₦)", "Total Contribution Payable (₦)"];
    const [reportData, setReportData] = useState([])
    const [pensionData, setPensionData] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })
    // let total = 0;

    const getData = async () => {

        await axios.get(`${API_URL}human-resources/report/salary-months`, login?.token).then((res) => {
            setIsLoading(false)
        }).catch((e) => {
            toast.error(e.response.data)
        })

        await axios.get(`${API_URL}human-resources/pension/list`, login?.token).then((res) => {
            setPensionData(res.data)
        }).catch((e) => {
            console.log(e.response.data)
        })


    }
    const  showTable = () => {

        try {
            return reportData.filter(e => e.employee_contribution > 0).map((item, index) => {
                let employer_arrears = item.employee_pension_arrears > 0 ? item.employee_pension_arrears * (0.1 / 0.08) : 0;
                return (
                    <tr key={index}>
                        <td className="text-xs font-weight-bold">{index +1}</td>
                        <td className="text-xs font-weight-bold">{item.Surname}</td>
                        <td className="text-xs font-weight-bold">{item.OtherNames}</td>
                        <td className="text-xs font-weight-bold">{item.rsa_pin}</td>
                        <td className="text-xs font-weight-bold">{item.PensionName}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.pensionable_emolument)}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.employer_contribution)}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(employer_arrears)}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.employee_contribution)}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.employee_pension_arrears)}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.voluntary_contribution)}</td>
                        <td className="text-xs font-weight-bold">{moneyFormat(item.employer_contribution + employer_arrears + item.employee_contribution + item.employee_pension_arrears + item.voluntary_contribution)}</td>
                    </tr>
                );
            });


        } catch (e) {
            alert(e.message);
        }

    };

    const  getTotal = () => {
        let total_amount = 0;
        reportData.filter(e => e.employee_contribution > 0).map((item, index) => {
            let employer_arrears = item.employee_pension_arrears > 0 ? item.employee_pension_arrears * (0.1 / 0.08) : 0;
            total_amount += item.employer_contribution + employer_arrears + item.employee_contribution + item.employee_pension_arrears + item.voluntary_contribution;
        });
        return total_amount;
    }


    const getSalaryReport = async (salary_month) => {

        if (salary_month === "") {
        } else {
            setIsSubmitting(true)
            await axios.get(`${API_URL}report/payroll/schedule?salary_month=${salary_month}`, login?.token)
                .then((res) => {
                    if (res.data.length > 0) {
                        console.log(res.data)
                        setReportData(res.data)
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error("error getting allowances")
                })
        }
    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        getSalaryReport(val)
    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Pension Schedule"
                                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Report', link: '/report/dashboard' },
                                    { name: 'Pension Schedule', link: '/report/pension-schedule' }]}
                />

                <div className="row  no-printing">
                    <ReportSidebar/>
                    <div className="col-xl-10">
                        <div className=' mb-5'>
                            <div className='card card-body'>
                                <div className="row">
                                    <div className="col-md-12 pb-3">
                                        <div className="form-group">
                                            <label htmlFor="month_id">Select Salary Month</label>
                                            <input type="month" id="month_id"
                                                   className="form-control"
                                                   value={formData.month_id}
                                                   onChange={onChange}
                                                   max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                                   required />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isSubmitting ?
                                <Loader />
                                :
                                <div className="">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <h3 className="card-title">Pension Schedule Report</h3>
                                                <PensionDataTable header={header1} date={formatDateAndTime(formData.month_id, 'month_and_year')} caption={`${currencyConverter(getTotal() ?? 0)}`} body={showTable()} title={`Pension Schedule Report For ${formatDateAndTime(formData.month_id, 'month_and_year')}`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </>
            {
                pensionData.map((element, int) => {
                    const  getTotal3 = () => {
                        let total_amount = 0;
                        reportData.filter(e => e.pension_id === element.pension_id && e.employee_contribution > 0).map((item, index) => {
                            let employer_arrears = item.employee_pension_arrears > 0 ? item.employee_pension_arrears * (0.1 / 0.08) : 0;
                            total_amount += item.employer_contribution + employer_arrears + item.employee_contribution + item.employee_pension_arrears + item.voluntary_contribution;
                        });
                        return total_amount;
                    }
                  return  <div key={int} className="row margin-up2 break-after view-div" style={{marginLeft: '0px', marginRight: '0px', visibility: 'hidden'}}>
                        <div className="flex-container" style={{ width: '100%', margin: '0px' }}>
                            <img src="https://veritas.gargatech.com/logo.png" width="100" height="80" />
                            <div className="flex-item3 text-uppercase">
                                <h4 style={{ marginBottom: '8px' }}>
                                    <b>{HOTEL_NAME}</b> <br />
                                    <span>FOR THE PERIOD: {formatDateAndTime(formData.month_id, 'month_and_year')}</span> <br />
                                    <span>PFA: {element.pension_name}</span> <br />
                                    <span>RECEIVING BANK: {element.account_name}</span> <br />
                                    <span>EMPLOYER'S CODE: {COMPANY_PENSION_EMPLOYER_CODE}</span>
                                </h4>
                            </div>
                            <div className="flex-item text-uppercase">
                                <h4>
                                    <span className="mgb">TOTAL AMOUNT: {currencyConverter(getTotal3())}</span> <br />
                                    <span>RECEIVING ACCOUNT NUMBER: {element.account_number}</span> <br />
                                    <span>SORT CODE: {COMPANY_SORT_CODE}</span>
                                </h4>
                            </div>
                            <div style={{ borderBottom: '1px solid #cccccc', display: 'none' }}></div><hr/>
                        </div>
                        <hr/>
                        <table className="table caption-top myTable align-items-center justify-content-center mb-5 mt-5" style={{border: '1px solid #cccccc', borderBottom: '1px solid #cccccc'}}>
                            <thead>
                            <tr>
                                {
                                    header.length > 0 &&  header.map((item, index) => {
                                        return (
                                            <th key={index} style={{fontWeight: 'bold'}} className="text-uppercase text-dark text-sm font-weight-bolder bg-secondary opacity-7 ps-2">{item}</th>
                                        )
                                    })
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                reportData.filter(e => e.pension_id === element.pension_id).length > 0 ? reportData.filter(e => e.pension_id === element.pension_id && e.employee_contribution > 0).map((item, index) => {
                                    let employer_arrears = item.employee_pension_arrears > 0 ? item.employee_pension_arrears * (0.1 / 0.08) : 0;
                                    return (
                                        <tr key={index}  style={{border: '1px solid #cccccc', borderBottom: '1px solid #cccccc'}}>
                                            <td className="text-xs font-weight-bold">{index +1}</td>
                                            <td className="text-xs font-weight-bold">{item.Surname}</td>
                                            <td className="text-xs font-weight-bold">{item.OtherNames}</td>
                                            <td className="text-xs font-weight-bold">{item.rsa_pin}</td>
                                            {/*<td className="text-xs font-weight-bold">{item.PensionName}</td>*/}
                                            <td className="text-xs font-weight-bold">{moneyFormat(item.pensionable_emolument)}</td>
                                            <td className="text-xs font-weight-bold">{moneyFormat(item.employer_contribution)}</td>
                                            <td className="text-xs font-weight-bold">{moneyFormat(employer_arrears)}</td>
                                            <td className="text-xs font-weight-bold">{moneyFormat(item.employee_contribution)}</td>
                                            <td className="text-xs font-weight-bold">{moneyFormat(item.employee_pension_arrears)}</td>
                                            <td className="text-xs font-weight-bold">{moneyFormat(item.voluntary_contribution)}</td>
                                            <td className="text-xs font-weight-bold">{moneyFormat(item.employer_contribution + employer_arrears + item.employee_contribution + item.employee_pension_arrears + item.voluntary_contribution)}</td>
                                        </tr>
                                    );
                                }) : <></>
                            }
                            </tbody>
                        </table>
                    </div>
                })
            }

        </div>

    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(PensionSchedule);
