import {HOTEL_CODE} from "../context/connection-string";

export const setLoginDetails = (p) => {
    return {
        type: `SET_${HOTEL_CODE}_LOGIN_DETAILS`,
        payload: p,
    };
};
export const setPermissionDetails = (p) => {
    return {
        type: `SET_${HOTEL_CODE}_PERMISSION_DETAILS`,
        payload: p,
    };
};
export const setGeneralDetails = (p) => {
    return {
        type: `SET_${HOTEL_CODE}_GENERAL_DETAILS`,
        payload: p,
    };
};