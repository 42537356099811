import React from 'react'

export default function DepartmentsForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="department_name">Department Name</label>
                        <input type="text" id="department_name" className="form-control" value={props.values.department_name} onChange={props.onChange} required />
                    </div>
                </div>

                <div className="col-md-12 pb-3 mt-5 mb-3">
                    <div className="form-group">
                        <label htmlFor="division">Division</label>
                        <select className='form-control' id="division" onChange={props.onChange} value={props.values.division} required  >
                            <option value={""}> -Select Division- </option>
                            {
                                props.divisionList.length > 0 &&
                                props.divisionList?.map((x, i) => {
                                    return (
                                        <option key={i} value={x.division_id}> {x.division_id} -- {x.division_name} </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="col-md-12 pb-3 mt-5 mb-3">
                    <div className="form-group">
                        <label htmlFor="manager_id">Department Head</label>
                        <select className='form-control' id="hod" onChange={props.onChange} value={props.values.hod} required  >
                            <option value={""}> -Select Department Head- </option>
                            {
                                props.employeeList.length > 0 &&
                                props.employeeList?.map((x, i) => {
                                    return (
                                        <option key={i} value={x.employee_id}> {x.employee_id} -- {x.full_name} </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>

                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
