import React from "react";

export function DashboardPageTitle({title}) {
    return (
        <div className="toolbar mb-n1 pt-3 mb-lg-n3 pt-lg-6">
            <div className="page-title d-flex flex-column align-items-start me-3 py-2 py-lg-0 gap-2">
                <h1 className="d-flex text-dark fw-bold m-0 fs-3">Dashboard
                    <span className="h-20px border-gray-400 border-start mx-3"/>
                    <small className="text-gray-500 fs-7 fw-semibold my-1">{title}</small>
                </h1>
            </div>
        </div>
    )
}
