export default function UserGroupForm(props) {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="row">
                <div className="col-md-12 pb-3">
                    <div className="form-group">
                        <label htmlFor="group_name">Enter Group Name</label>
                        <input type="text" id="group_name" className="form-control" value={props.value.group_name} onChange={props.onChange}/>
                    </div>
                </div>
                <button type="submit" className="btn btn-lg btn-block btn-primary">Submit</button>
            </div>
        </form>
    )
}
