import {connect} from "react-redux";
import GeneralToolbar from "../../../../component/include/general-toolbar";
import ReportTable from "../../../../component/table/report-table";
import {useEffect, useState} from "react";
import { toast } from "react-toastify";
import {API_URL} from "../../../../context/connection-string";
import axios from "axios";
import Loader from "../../../../component/loader/loader";
import {formatDateAndTime, loginValidation} from "../../../../context/function";

function UserGroupPermission(props) {
    const [isLoading, setIsLoading] = useState(true);
    const token = props.loginData[0].token;
    const inserted_by = props.loginData[0].employee_id;

    const [menuList, setMenuList] = useState([]);
    const [permissionList, setPermissionList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const permission_columns = ["S/N", "Menu Name", "Page Name", "Added By", "Added Date", "Action"];
    const [permissionTableData, setPermissionTableData] = useState([]);

    const fetchData = async (group_id=false) => {
        await axios.get(`${API_URL}settings/permission/view/list`, token)
            .then(res => {
                if (res.data.message === 'success') {
                    setMenuList(res.data.menu_list)
                    setPermissionList(res.data.permission_list)
                    setGroupList(res.data.group_list)
                    if (group_id) {
                        generateReport(group_id, res.data.permission_list)
                    }
                } else {
                    toast.info("Something went wrong. Please try again!")
                }
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(e.message)
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    const handleGroupChange = (e) => {
      const group_id = e.target.value;
        generateReport(group_id, permissionList)
    }

    const generateReport = (group_id, permission_list) => {
        if (group_id !== "") {
            let perm_data = [];
            menuList.length > 0 &&
            menuList.map((menu, i) => {
                const perm_exist = permission_list.filter(p => p.group_id == group_id && p.sub_menu_id === menu.sub_menu_id);
                const added_by = perm_exist.length > 0 ? perm_exist[0].inserted_name : '--';
                const added_date = perm_exist.length > 0 ? formatDateAndTime(perm_exist[0].inserted_date, 'date') : '--';
                const action = perm_exist.length > 0 ?
                    <button onClick={() => {handlePermissionSubmission(group_id, menu.sub_menu_id, 'remove')}} className={"btn btn-danger btn-sm"}><i className={"fa fa-trash"} /></button> :
                    <button onClick={() => {handlePermissionSubmission(group_id, menu.sub_menu_id, 'add')}} className={"btn btn-primary btn-sm"}><i className={"fa fa-plus"} /></button>
                perm_data.push([i+1, menu.menu_name, menu.sub_menu_name, added_by, added_date, action])
            })
            setPermissionTableData(perm_data)
        } else {
            setPermissionTableData([]);
        }
    }

    const handlePermissionSubmission = async (group_id, sub_menu_id, action) => {
        const sendData = {
            group_id: group_id,
            sub_menu_id: sub_menu_id,
            inserted_by: inserted_by
        }
        if (action === 'add') {
            await axios.post(`${API_URL}settings/group/permission/add`, sendData, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Permission Added Successfully");
                        fetchData(group_id);
                    } else {
                        toast.error("Something went wrong adding permission. please try again.")
                    }
                })
                .catch(e => {
                    toast.error(e.message)
                    toast.error(`${e.response.statusText}: ${e.response.data}`)
                })
        } else {
            await axios.delete(`${API_URL}settings/group/permission/delete/${group_id}/${sub_menu_id}`, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Permission Removed Successfully");
                        fetchData(group_id);
                    } else {
                        toast.error("Something went wrong removing permission. please try again.")
                    }
                })
                .catch(e => {
                    toast.error(e.message)
                    toast.error(`${e.response.statusText}: ${e.response.data}`)
                })
        }
    }

    useEffect(() => {
        if (loginValidation(window.location.pathname, props.permissionData) !== 'permitted')
            window.location.href = '/';
        fetchData()
    },[]);


    return isLoading ? <Loader /> : (
        <>
            <GeneralToolbar title="User Group Permission" pages={[{name: 'Dashboard', link: '/'}, {name: 'Settings', link: '/'}, {name: 'User Group Permission', link: '/'}]} />
            {/*begin::Table*/}
            <div className="row mb-4">
                <div className="col-md-12">
                    <div className="form-group">
                        <label htmlFor="group_id">Select User Group</label>
                        <select id="group_id" className="form-control" onChange={handleGroupChange}>
                            <option value="">Select Option</option>
                            {
                                groupList.length > 0 && groupList.map((r,i) => {
                                    return <option key={i} value={r.group_id}>{r.group_name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>
            <ReportTable title={"User Group Permission"} columns={permission_columns} data={permissionTableData} />
            {/*end::Table*/}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        permissionData: state.PermissionDetails
    };
};

export default connect(mapStateToProps, null)(UserGroupPermission);
