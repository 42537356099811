import {connect} from "react-redux";
import GeneralToolbar from "../../../../component/include/general-toolbar";
import ReportTable from "../../../../component/table/report-table";
import BootstrapModal from "../../../../component/modal/bootstrap-modal";
import React, {useEffect, useState} from "react";
import { toast } from "react-toastify";
import {API_URL} from "../../../../context/connection-string";
import axios from "axios";
import Loader from "../../../../component/loader/loader";
import {showAlert} from "../../../../context/function";
import AboutUsForm from "./about-us-form";
import SliderForm from "./slider-form";
import PartnerForm from "./partner-form";

function WebsiteManagementSettings(props) {
    const token = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModal2, setOpenModal2] = useState(false);
    const [openModal3, setOpenModal3] = useState(false);
    const initialSliderValue = {  slider_id: '', title: '', caption: '', btn_name: '', btn_link: '', image_path: '', update_image: false, status: '', inserted_by: '', updated_by: ''}
    const initialPartnerValue = {  partner_id: '', partner_name: '', image_path: '', update_image: false, inserted_by: '', updated_by: ''}
    const initialAboutValue = {  about_id: '', title: '', caption: '', description: '', vision: '', mission: '', brand_personality: '', image_path: '', inserted_by: '', updated_by: '', update_image: false,}
    const [formData, setFormData] = useState(initialPartnerValue);
    const [formData2, setFormData2] = useState(initialSliderValue);
    const [formData3, setFormData3] = useState(initialAboutValue);
    const columns = ["S/N", "partner_name", "partner_url", "Image Path", "Inserted By", "Action"];
    const columns2 = ["S/N", "title", "caption", "status", "Image Path", "Inserted By", "Action"];
    const columns3 = ["S/N", "title", "mission", "vision", "Image Path", "Inserted By", "Action"];
    const [tableData,setTableData] = useState([]);
    const [tableData2,setTableData2] = useState([]);
    const [tableData3,setTableData3] = useState([]);

    const fetchWebsiteManagementData = async () => {
        await axios.get(`${API_URL}settings/website_management_data/list`, token)
            .then(res => {
                if (res.data.message === 'success') {
                    const sliders = [];
                    const about = [];
                    const partner = [];
                    if (res.data.slider_list.length > 0) {
                        res.data.slider_list.map((r, i) => {
                            sliders.push([i+1, r.title, r.caption, r.status ===0? 'Draft' : 'Published',r.image_path === null ? 'No Image' : <span>
                                <div className="symbol symbol-100px symbol-lg-80px symbol-fixed position-relative">
                            <img src={`${API_URL}public/slider/${r.image_path}`} alt="image" />
                            <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-10px w-10px" />
                        </div>
                            </span>,  r.inserted_name,
                                (
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                            onOpenModal2();
                                            setFormData2({
                                                ...formData2,
                                                title: r.title,
                                                image_path: r.image_path,
                                                inserted_date: r.inserted_date,
                                                caption: r.caption,
                                                btn_name: r.btn_name,
                                                btn_link: r.btn_link,
                                                status: r.status,
                                                inserted_by: r.inserted_by,
                                                slider_id: r.slider_id,
                                                update_image: false
                                            })
                                        }
                                        }
                                    >
                                        <i className="fa fa-pen" />
                                    </button>
                                )
                            ])
                        })
                        setTableData2(sliders)
                    }

                    if (res.data.about_list.length > 0) {
                        res.data.about_list.map((r, i) => {
                            about.push([i+1, r.title, r.mission === "" ? "---" : r.mission, r.vision === "" ? "---" : r.vision, r.image_path === null ? "No Image" : <span>
                                <div className="symbol symbol-100px symbol-lg-80px symbol-fixed position-relative">
                            <img src={`${API_URL}public/about-us/${r.image_path}`} alt="image" />
                            <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-10px w-10px" />
                        </div>
                            </span>,  r.inserted_name,
                                (
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                            console.log(r.title)
                                            onOpenModal3();
                                            setFormData3({
                                                ...formData3,
                                                title: r.title,
                                                caption: r.caption,
                                                description: r.description,
                                                vision: r.vision,
                                                mission: r.mission,
                                                brand_personality: r.brand_personality,
                                                image_path: r.image_path,
                                                inserted_date: r.inserted_date,
                                                inserted_by: r.inserted_by,
                                                about_id: r.about_id,
                                                update_image: false
                                            })
                                        }
                                        }
                                    >
                                        <i className="fa fa-pen" />
                                    </button>
                                )
                            ])
                        })
                        setTableData3(about)
                    }

                    if (res.data.partner_list.length > 0) {
                        res.data.partner_list.map((r, i) => {
                            partner.push([i+1, r.partner_name, r.partner_url, r.image_path === null ? 'No Image' : <span>
                                <div className="symbol symbol-100px symbol-lg-80px symbol-fixed position-relative">
                            <img src={`${API_URL}public/partner/${r.image_path}`} alt="image" />
                            <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-10px w-10px" />
                        </div>
                            </span>,  r.inserted_name,
                                (
                                    <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                            onOpenModal();
                                            setFormData({
                                                ...formData,
                                                partner_name: r.partner_name,
                                                partner_url: r.partner_url,
                                                image_path: r.image_path,
                                                inserted_date: r.inserted_date,
                                                inserted_by: r.inserted_by,
                                                partner_id: r.partner_id,
                                                update_image: false
                                            })
                                        }
                                        }
                                    >
                                        <i className="fa fa-pen" />
                                    </button>
                                )
                            ])
                        })
                        setTableData(partner)
                    }

                } else {
                    toast.info("Something went wrong. Please try again!")
                }
                setIsLoading(false)
            })
            .catch(e => {
                toast.error(`${e.response.statusText}: ${e.response.data}`)
            })
    }

    useEffect(() => {
        fetchWebsiteManagementData()
    },[formData])

    const onOpenModal = () => {
        setFormData(initialPartnerValue)
        setOpenModal(true);
    }
    const onOpenModal2 = () => {
        setFormData2(initialSliderValue)
        setOpenModal2(true);
    }
    const onOpenModal3 = () => {
        setFormData3(initialAboutValue)
        setOpenModal3(true);
    }
    const onDescriptionChange = (e) => {
        setFormData3({
            ...formData3,
            "description": e
        })
    }
    const handleFormValueChange = (e) => {
        if (e.target.id === "image_path") {
            const file = e.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!");
                return;
            }
            if (file.size > 1000000) {
                toast.error("max file size is 1mb")
                return;
            }
            setFormData({
                ...formData,
                [e.target.id]: e.target.files[0],
                update_image: true
            })
            return;
        }
        setFormData({
            ...formData,
            [e.target.id] : e.target.value
        })
    }
    const handleFormValueChange2 = (e) => {
        if (e.target.id === "image_path") {
            const file = e.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!");
                return;
            }
            if (file.size > 1000000) {
                toast.error("max file size is 1mb")
                return;
            }

            setFormData2({
                ...formData2,
                [e.target.id]: e.target.files[0],
                update_image: true
            })
            return;
        }
        setFormData2({
            ...formData2,
            [e.target.id] : e.target.value
        })
    }
    const handleFormValueChange3 = (e) => {
        if (e.target.id === "image_path") {
            const file = e.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!");
                return;
            }
            if (file.size > 1000000) {
                toast.error("max file size is 1mb")
                return;
            }

            setFormData3({
                ...formData3,
                [e.target.id]: e.target.files[0],
                update_image: true
            })
            return;
        }
        setFormData3({
            ...formData3,
            [e.target.id] : e.target.value
        })
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        if (formData.partner_name.trim() === "") {
            toast.error("Please enter the partner name");
            return false;
        }
        if (formData.partner_url.trim() === "") {
            toast.error("Please enter the partner url");
            return false;
        }
        setIsFormLoading(true)
        if (formData.partner_id === '') {
            let sendData = {
                ...formData,
                inserted_by: props.loginData[0].employee_id,
            }
            await axios
                .post(`${API_URL}settings/partner/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.image_path !== ""){
                            const datum = new FormData();
                            datum.append("partner_id", result.data.partner_id)
                            datum.append("file", formData.image_path)
                            axios.patch(`${API_URL}settings/partner/passport`, datum)
                                .then((res) => {
                                })
                            toast.success("Partner Added Successfully");
                            fetchWebsiteManagementData();
                            setFormData({
                                ...formData,
                                ...initialPartnerValue
                            })
                            setIsFormLoading(false)
                            setOpenModal(false)
                        }
                        toast.success("Partner Added Successfully");
                        fetchWebsiteManagementData();
                        setFormData({
                            ...formData,
                            ...initialPartnerValue
                        })
                        setIsFormLoading(false)
                        setOpenModal(false)
                    } else {
                        setIsFormLoading(false)
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false)
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });

        } else {
            let sendData = {
                ...formData,
                updated_by: props.loginData[0].employee_id
            }
            await axios
                .patch(`${API_URL}settings/partner/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData.update_image === true){
                            const datum = new FormData();
                            datum.append("partner_id", formData.partner_id)
                            datum.append("file", formData.image_path)
                            axios.patch(`${API_URL}settings/partner/passport`, datum)
                                .then((res) => {
                                })
                            toast.success("Partner Updated Successfully");
                            fetchWebsiteManagementData();
                            setFormData({
                                ...formData,
                                ...initialPartnerValue
                            })
                            setIsFormLoading(false)
                            setOpenModal(false)
                        }
                        toast.success("Partner Updated Successfully");
                        fetchWebsiteManagementData();
                        setFormData({
                            ...formData,
                            ...initialPartnerValue
                        })
                        setIsFormLoading(false)
                        setOpenModal(false)
                    } else {
                        setIsFormLoading(false)
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false)
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }

    }
    const onFormSubmit2 = async (e) => {
        e.preventDefault();
        if (formData2.title.trim() === "") {
            toast.error("Please enter title");
            return false;
        }
        if (formData2.caption.trim() === "") {
            toast.error("Please enter caption");
            return false;
        }
        if (formData2.btn_name.trim() === "") {
            toast.error("Please enter btn name");
            return false;
        }
        if (formData2.btn_link.trim() === "") {
            toast.error("Please enter btn link");
            return false;
        }
        if (formData2.image_path === "") {
            toast.error("Please upload image");
            return false;
        }
        if (formData2.status.toString().trim() === "") {
            toast.error("Please select status");
            return false;
        }
        setIsFormLoading(true)

        toast.info("Submitting, please wait!!!");
        if (formData2.slider_id === '') {
            let sendData = {
                ...formData2,
                inserted_by: props.loginData[0].employee_id
            }
            await axios
                .post(`${API_URL}settings/slider/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData2.image_path !== ""){
                            const datum = new FormData();
                            datum.append("slider_id", result.data.slider_id)
                            datum.append("file", formData2.image_path)
                            axios.patch(`${API_URL}settings/slider/passport`, datum)
                                .then((res) => {
                                })
                            toast.success("Slider added Successfully");
                            fetchWebsiteManagementData();
                            setFormData2({
                                ...formData2,
                                ...initialSliderValue
                            })
                            setIsFormLoading(false)
                            setOpenModal2(false)
                        }
                    } else {
                        setIsFormLoading(false)
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false)
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });

        } else {
            let sendData = {
                ...formData2,
                updated_by: props.loginData[0].employee_id
            }
            await axios
                .patch(`${API_URL}settings/slider/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData2.update_image === true){
                            const datum = new FormData();
                            datum.append("slider_id", formData2.slider_id)
                            datum.append("file", formData2.image_path)
                            axios.patch(`${API_URL}settings/slider/passport`, datum)
                                .then((res) => {
                                })
                            toast.success("Slider Updated Successfully");
                            fetchWebsiteManagementData();
                            setFormData2({
                                ...formData2,
                                ...initialSliderValue
                            })
                            setIsFormLoading(false)
                            setOpenModal2(false)
                        }
                        toast.success("Slider Updated Successfully");
                        fetchWebsiteManagementData();
                        setFormData2({
                            ...formData2,
                            ...initialSliderValue
                        })
                        setIsFormLoading(false)
                        setOpenModal2(false)
                    } else {
                        setIsFormLoading(false)
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false)
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }

    }
    const onFormSubmit3 = async (e) => {
        e.preventDefault();
        if (formData3.title.trim() === "") {
            toast.error("Please enter title");
            return false;
        }
        if (formData3.caption.trim() === "") {
            toast.error("Please enter caption");
            return false;
        }
        // if (formData3.vision.trim() === "") {
        //     toast.error("Please enter vision");
        //     return false;
        // }
        // if (formData3.mission.trim() === "") {
        //     toast.error("Please enter mission");
        //     return false;
        // }
        if (formData3.description.trim() === "") {
            toast.error("Please enter description");
            return false;
        }
        setIsFormLoading(true)
        if (formData3.about_id === '') {
            let sendData = {
                ...formData3,
                inserted_by: props.loginData[0].employee_id
            }
            await axios
                .post(`${API_URL}settings/about/add`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData3.image_path !== ""){
                            const datum = new FormData();
                            datum.append("about_id", result.data.about_id)
                            datum.append("file", formData3.image_path)
                            axios.patch(`${API_URL}settings/about/passport`, datum)
                                .then((res) => {
                                })
                            toast.success("About Added Successfully");
                            fetchWebsiteManagementData();
                            setFormData3({
                                ...formData3,
                                ...initialAboutValue
                            })
                            setIsFormLoading(false)
                            setOpenModal3(false)
                        }
                        toast.success("About Added Successfully");
                        fetchWebsiteManagementData();
                        setFormData3({
                            ...formData3,
                            ...initialAboutValue
                        })
                        setIsFormLoading(false)
                        setOpenModal3(false)
                    } else {
                        setIsFormLoading(false)
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false)
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });

        } else {
            let sendData = {
                ...formData3,
                updated_by: props.loginData[0].employee_id
            }
            await axios
                .patch(`${API_URL}settings/about/update`, sendData, token)
                .then((result) => {
                    if (result.data.message === "success") {
                        if (formData3.update_image === true){
                            const datum = new FormData();
                            datum.append("about_id", formData3.about_id)
                            datum.append("file", formData3.image_path)
                            axios.patch(`${API_URL}settings/about/passport`, datum)
                                .then((res) => {
                                })
                            toast.success("About Updated Successfully");
                            fetchWebsiteManagementData();
                            setFormData3({
                                ...formData3,
                                ...initialAboutValue
                            })
                            setIsFormLoading(false)
                            setOpenModal3(false)
                        }
                        toast.success("About Updated Successfully");
                        fetchWebsiteManagementData();
                        setFormData3({
                            ...formData3,
                            ...initialAboutValue
                        })
                        setIsFormLoading(false)
                        setOpenModal3(false)
                    } else {
                        setIsFormLoading(false)
                        showAlert(
                            "ERROR",
                            "Something went wrong. Please try again!",
                            "error"
                        );
                    }
                })
                .catch((error) => {
                    setIsFormLoading(false)
                    showAlert(
                        "NETWORK ERROR",
                        "Please check your connection and try again!",
                        "error"
                    );
                });
        }

    }


    return isLoading ? <Loader /> : (
        <>
            <div className="">
                <GeneralToolbar title="Website Management" pages={[{name: 'Dashboard', link: '/'}, {name: 'settings', link: '/'}, {name: 'website-management', link: '/'}]} />
            </div>
            <BootstrapModal
                openModal={openModal3}
                closeModal={setOpenModal3}
                size= "xl"
                title="About Us Form">
                <AboutUsForm value = {formData3}  isFormLoading={isFormLoading} onChange={handleFormValueChange3} onSubmit={onFormSubmit3} onDescriptionChange={onDescriptionChange} />
            </BootstrapModal>

            <BootstrapModal
                openModal={openModal2}
                closeModal={setOpenModal2}
                size= "xl"
                title="Slider Form">
                <SliderForm value = {formData2} isFormLoading={isFormLoading} onChange={handleFormValueChange2} onSubmit={onFormSubmit2} />
            </BootstrapModal>

            <BootstrapModal
                openModal={openModal}
                closeModal={setOpenModal}
                size= "xl"
                title="Partners Form">
                <PartnerForm value = {formData} isFormLoading={isFormLoading} onChange={handleFormValueChange} onSubmit={onFormSubmit} />
            </BootstrapModal>

            {/*begin::Table*/}

            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 ">
                <li className="nav-item">
                    <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                       href="#kt_ecommerce_add_product_general">Slider</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                       href="#kt_ecommerce_add_product_partners">Partners</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                       href="#kt_ecommerce_add_product_advanced">About Us</a>
                </li>
            </ul>


            <div className="tab-content mt-4">
                <div className="tab-pane fade show active" id="kt_ecommerce_add_product_general" role="tab-panel">
                    <div id="kt_toolbar_container" className=" d-flex flex-stack flex-wrap mb-3">
                        <div className="page-title d-flex flex-column align-items-start me-3 py-2 py-lg-0 gap-2"/>
                        <button className="btn btn-sm btn-primary float-right" onClick={onOpenModal2}>Add Slider</button>
                    </div>
                    <ReportTable title={"Slider"} columns={columns2} data={tableData2} />
                </div>
                <div className="tab-pane fade" id="kt_ecommerce_add_product_partners" role="tab-panel">
                    <div id="kt_toolbar_container" className=" d-flex flex-stack flex-wrap mb-3">
                        <div className="page-title d-flex flex-column align-items-start me-3 py-2 py-lg-0 gap-2"/>
                        <button className="btn btn-sm btn-primary float-right" onClick={onOpenModal}>Add Partner</button>
                    </div>
                    <ReportTable title={"Partner"} columns={columns} data={tableData} />
                </div>
                <div className="tab-pane fade" id="kt_ecommerce_add_product_advanced" role="tab-panel">
                    <div id="kt_toolbar_container" className=" d-flex flex-stack flex-wrap mb-3">
                        <div className="page-title d-flex flex-column align-items-start me-3 py-2 py-lg-0 gap-2"/>
                        <button className="btn btn-sm btn-primary float-right" onClick={onOpenModal3}>Add About Us</button>
                    </div>
                    <ReportTable title={"About Us"} columns={columns3} data={tableData3} />
                </div>
            </div>

            {/*end::Table*/}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(WebsiteManagementSettings);
