import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import EmployeesForm from './employee-form';
import { showAlert } from '../../../context/function';

function ManageEmployees(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const columns = ["SN", "STAFF ID",  "TITLE", "SURNAME", "OTHER NAMES", "EMAIL", "PHONE", "DEPARTMENT", "DIVISION", "STATUS", "CHANGE STATUS", "ACTION"];
    const [departmentList, setDepartmentList] = useState([]);
    const [titlesList, setTitlesList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showUpload, setShowUpload] = useState(false)
    const [showUpload2, setShowUpload2] = useState(false)
    const [divisionList, setDivisionList] = useState([])
    const [designationList, setDesignationList] = useState([])
    const [pensionList, setPensionList] = useState([])
    const [emolumentSettings, setEmolumentsSettings] = useState([]);
    const [employeeEmoluments, setEmployeeEmoluments] = useState([]);

    const [formData, setFormData] = useState({
        employee_id: "", pension_id: "", staff_tin_number: "", rsa_pin: "", nhf_number: "", bank_code: "", beneficiary_branch: "", Surname: "", OtherNames: "", email_address: "", phone_number: "", address: "", date_of_birth: "", hire_date: "", job_title_id: "", pay_point: "", department_id: "", staff_id: "", password: "", status: "", image_path: "", bank_name: "", account_number: "", sort_code: "", department_name: "",
        inserted_by: props.loginData[0]?.employee_id, update_passport: false, gender: "", employeeFile: "", salaryFile: "",  salary_step: "",  salary_rank: "", division_id: "", designation_id: "", staff_category: "", employee_type: "", isBulkUploading:""
    })

    const getData = async () => {

        await axios.get(`${API_URL}human-resources/employee/meta-data`, login?.token).then((res) => {
            setDepartmentList(res.data?.departments)
            setTitlesList(res.data?.titles);

        })

        const emoluments = await axios.get(`${API_URL}human-resources/emolument/list`, login?.token)
        setEmolumentsSettings(emoluments.data)

        await getDesignation();
        await getPensions();
        await getEmployees();
        await getDivisions();

    }

    const getDivisions = async () => {

        await axios.get(`${API_URL}human-resources/division/list`, login?.token).then((res) => {
            setDivisionList(res.data);
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }

    const getDesignation = async () => {

        await axios.get(`${API_URL}human-resources/designation/option-list`, login?.token).then((res) => {
            setDesignationList(res.data);
        }).catch((e) => {
            toast.error(e.response.data)
        })

    }

    const getPensions = async () => {

        await axios.get(`${API_URL}human-resources/pension/list`, login?.token).then((res) => {
            setPensionList(res.data);
        }).catch((e) => {
            toast.error(e.response.data)
        })
    }

    const getEmployees = async () => {
        await axios.get(`${API_URL}human-resources/employee/list`, login?.token)
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.staff_id.toString(),
                            x.title_name,
                            x.Surname,
                            x.OtherNames,
                            x.email_address,
                            x.phone_number,
                            x.department_name,
                            x.division_name,
                            x.status,
                            <div>
                                <input type='checkbox' onChange={handleCheckbox} checked={x.status === "Active"} data={JSON.stringify(x)} /></div>,
                            <div >
                                <button className='btn btn-sm btn-primary'
                                    onClick={async () => {
                                        const emoluments_score = await axios.get(`${API_URL}human-resources/employee-income/${x.staff_id}`, login?.token);
                                        setEmployeeEmoluments(emoluments_score.data)
                                        setFormData({
                                            ...formData,
                                            employee_id: x.employee_id,
                                            Surname: x.Surname,
                                            OtherNames: x.OtherNames,
                                            email_address: x.email_address,
                                            phone_number: x.phone_number,
                                            address: x.address,
                                            date_of_birth: x.date_of_birth,
                                            hire_date: x.hire_date,
                                            job_title_id: x.job_title_id,
                                            department_id: x.department_id,
                                            department_name: x.department_name,
                                            staff_id: x.staff_id,
                                            status: x.status,
                                            image_path: x.image_path,
                                            // salary: x.salary,
                                            bank_name: x.bank_name,
                                            account_number: x.account_number,
                                            sort_code: x.sort_code,
                                            gender: x.gender,
                                            // is_fte: x.is_fte,
                                            division_id: x.division_id,
                                            pension_id: x.pension_id,
                                            employee_type: x.employee_type,
                                            salary_rank: x.salary_rank,
                                            salary_step: x.salary_step,
                                            pay_point: x.pay_point,
                                            designation_id: x.designation_id,
                                            staff_category: x.staff_category,
                                            staff_tin_number: x.staff_tin_number,
                                            rsa_pin: x.rsa_pin,
                                            nhf_number: x.nhf_number,
                                            bank_code: x.bank_code,
                                            beneficiary_branch: x.beneficiary_branch,
                                        })
                                        setOpenModal(true);
                                    }} >
                                    <i className='fa fa-edit' />&nbsp; Edit
                                </button>
                                {/*<Link className='btn btn-sm btn-secondary ms-4' to={`/human-resources/employee-management/${x.employee_id}`}>*/}
                                {/*    <i className='fa fa-eye' />    Details*/}
                                {/*</Link>*/}
                            </div>
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error("error getting departments")
            })
    }

    useEffect(() => {
        getData();
    }, [])

    const handleCheckbox = (data) => {
        const module = JSON.parse(data.target.getAttribute('data'));
        const sendDatum = {
            employee_id: module.employee_id,
            status: "Active"
        }
        const sendDatumm = {
            employee_id: module.employee_id,
            status: "InActive"
        }
        if (data.target.checked) {
            axios.patch(`${API_URL}human-resources/employee/update/check`, sendDatum, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        getEmployees();
                        toast.success("Status changed to Active successfully");
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error(e.response.data)
                })

        } else {
            axios.patch(`${API_URL}human-resources/employee/update/check`, sendDatumm, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        getEmployees();
                        toast.success("Status changed to In-Active successfully");
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error(e.response.data)
                })
        }

    }


    const onChange = (e) => {
        if (e.target.id === "image_path") {
            const file = e.target.files[0]
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {

            } else {
                toast.error("Only .png, .jpg and .jpeg format allowed!");
                return;
            }
            if (file.size > 1000000) {
                toast.error("max file size is 1mb")
                return;

            }
            setFormData({
                ...formData,
                [e.target.id]: file,
                update_passport: true
            })
            return;
        }

        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        })
    }
    const onOpenModal = (status) => {
        setIsSubmitting(false);
        setFormData({
            ...formData,
            employee_id: "", pension_id: "", Surname: "", OtherNames: "", email_address: "", phone_number: "", address: "", date_of_birth: "", hire_date: "", job_title_id: "", pay_point: "", department_id: "", staff_id: "", password: "", status: "", image_path: "", bank_name: "", account_number: "", sort_code: "", department_name: "", gender: "", employeeFile: "", salary_step: "", division_id: "", designation_id: "", staff_category: "", employee_type: "", isBulkUploading:""        })
        setOpenModal(status);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        toast.info("please wait...")

        let AmountArray = [];
        let ItemIdArray = [];
        let input = document.getElementsByClassName("score");
        for (let i = 0; i < input.length; i++) {
            AmountArray.push(input[i].value)
            ItemIdArray.push(input[i].getAttribute("item_id"))
        }

        const SendData = {
            ...formData,
            item_id: ItemIdArray,
            item_amount: AmountArray,
            password: formData.phone_number
        }

        if (formData.employee_id === "") {
            await axios.post(`${API_URL}human-resources/employee/add`, SendData, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        if (formData.update_passport === true) {
                            const dt = new FormData();
                            dt.append("file", formData.image_path)
                            dt.append("email_address", formData.email_address)
                            await axios.patch(`${API_URL}human-resources/employee/update/passport`, dt)
                                .then((res) => {
                                })
                        }
                        getEmployees();
                        toast.success("employee added successfully");
                        onOpenModal(false);
                    } else if (res.data.message === "exists") {
                        toast.warning("employee already exists");
                    } else if (res.data.message === "no salary") {
                        toast.warning("Please add salary settings!")
                    }
                    setIsSubmitting(false)
                }).catch(() => {
                    setIsSubmitting(false);
                    toast.error(e.response.data)
                })
        } else {
            await axios.patch(`${API_URL}human-resources/employee/update`, SendData, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        if (formData.update_passport === true) {
                            const dt = new FormData();
                            dt.append("file", formData.image_path)
                            dt.append("email_address", formData.email_address)
                            await axios.patch(`${API_URL}human-resources/employee/update/passport`, dt)
                                .then((res) => {
                                })
                        }
                        getEmployees();
                        toast.success("employee updated successfully");
                        onOpenModal(false);
                    }else{
                        toast.error("Something went wrong updating employee");
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error(e.response.data)
                })
        }
    }



    const onFileChange = (e) => {
        const file = e.target.files[0];
        if (file.type !== "text/csv") {
            toast.error("Only .csv files are allowed")
            setFormData({
                ...formData,
                employeeFile: ""
            })
        } else {
            setFormData({
                ...formData,
                employeeFile: file
            })
        }
    }

    const onFileChange2 = (e) => {
        const file = e.target.files[0];
        if (file.type !== "text/csv") {
            toast.error("Only .csv files are allowed")
            setFormData({
                ...formData,
                salaryFile: ""
            })
        } else {
            setFormData({
                ...formData,
                salaryFile: file
            })
        }
    }

    const onUploadBulk = async () => {
        if (formData.employeeFile === "") {
            toast.error("please upload ca file")
            return
        }

        try {
            const fdt = new FormData();
            fdt.append("employeeFile", formData.employeeFile)
            fdt.append("inserted_by", formData.inserted_by)
            setFormData({
                ...formData,
                isBulkUploading: true
            })
            await axios.post(`${API_URL}human-resources/employee/bulk-entry`, fdt, login?.token)
                .then(res => {
                    const message = res.data.message;
                    if (message === 'success') {
                        getEmployees();
                        toast.success('Staff Uploaded successfully');
                        showAlert(
                            'Success',
                            `STAFF Uploaded successfully, 
                            ${res.data.duplicate_staff.length > 0 ?
                                `However, some staff already exist but their details has been updated` : ''} 
                                `,
                            `success`
                        )
                    } else {
                        toast.error('Something went wrong. Please try again!');
                    }
                })
                .catch(err => {
                    toast.err("NETWORK ERROR")
                })

        } catch (e) {
            toast.error("NETWROK ERROR")
        } finally {
            setFormData({
                ...formData,
                employeeFile: "",
                isBulkUploading: false
            })
        }
    }


    const onUploadSalaryBulk = async () => {
        if (formData.salaryFile === "") {
            toast.error("please upload ca file")
            return
        }

        try {
            const fdt = new FormData();
            fdt.append("salaryFile", formData.salaryFile)
            fdt.append("inserted_by", formData.inserted_by)
            setFormData({
                ...formData,
                isBulkUploading: true
            })
            await axios.post(`${API_URL}human-resources/emolument/bulk-entry`, fdt, login?.token)
                .then(res => {
                    const message = res.data.message;
                    if (message === 'success') {
                        getEmployees();
                        toast.success('Staff Salary Settings Uploaded successfully');
                        showAlert(
                            'Success',
                            `STAFF Salary Settings Uploaded successfully, 
                            ${res.data.duplicate_staff.length > 0 ?
                                `However, some staff already exist but their details has been updated` : ''} 
                                `,
                            `success`
                        )
                    } else {
                        toast.error('Something went wrong. Please try again!');
                    }
                })
                .catch(err => {
                    toast.err("NETWORK ERROR")
                })

        } catch (e) {
            toast.error("NETWROK ERROR")
        } finally {
            setFormData({
                ...formData,
                employeeFile: "",
                isBulkUploading: false
            })
        }
    }


    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Manage Employees"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Employee-Management', link: '/' },
                    { name: 'Manage Employees', link: '/employee-management/employees' }]}
                    button={
                        <div className='d-flex'>
                            <button className="btn btn-primary" onClick={() => { onOpenModal(true); setIsSubmitting(false) }}> + Add Employee</button>
                            <button className="btn btn-sm btn-secondary ms-4" onClick={() => { setShowUpload(!showUpload) }}>Bulk Upload (Employee)</button>
                            <button className="btn btn-sm btn-secondary ms-4" onClick={() => { setShowUpload2(!showUpload2) }}>Bulk Upload (Salary Settings)</button>
                        </div>
                    }
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    size={'xl'}
                    title="Manage Employees">
                    <EmployeesForm
                        values={formData}
                        setFormData={setFormData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        departmentList={departmentList}
                        titlesList={titlesList}
                        employeeList={employeeList}
                        isSubmitting={isSubmitting}
                        emolumentSettings={emolumentSettings}
                        employeeEmoluments={employeeEmoluments}
                        setEmployeeEmoluments={setEmployeeEmoluments}
                        setEmolumentsSettings={setEmolumentsSettings}
                        showTop
                        divisionList={divisionList}
                        pensionList={pensionList}
                        designationList={designationList}
                    />
                </BootstrapModal>

                {
                    showUpload &&
                    <div className='row mb-4'>
                        <div className="col-lg-6 col-md-4 pt-5">
                            <label htmlFor="">Upload File (<small><a className="text-danger italic" target="_blank" href={require("../../../assets/payroll_staff_template.csv")}>Click to donwload template</a></small>) &emsp; (<small><a className="text-danger italic" target="_blank" href={require("../../../assets/data_entry_guide.xlsx")}>Click to donwload template guide</a></small>) </label>
                            <input className="form-control" type="file" id="employeeFile" onChange={onFileChange} />
                        </div>
                        <div className="col-lg-6 col-md-4 pt-5">
                            <button type="button" disabled={formData.employeeFile === "" ? true : false} className="btn btn-md btn-primary mt-5 w-50" onClick={onUploadBulk}>Upload Employee</button>
                        </div>
                    </div>
                }

                {
                    showUpload2 &&
                    <div className='row mb-4'>
                        <div className="col-lg-6 col-md-4 pt-5">
                            <label htmlFor="">Upload File (<small><a className="text-danger italic" target="_blank" href={require("../../../assets/staff_salary_settings_template.csv")}>Click to download template</a></small>) &emsp; (<small><a className="text-danger italic" target="_blank" href={require("../../../assets/salary_settings_entry_guide.xlsx")}>Click to download template guide</a></small>) </label>
                            <input className="form-control" type="file" id="employeeFile" onChange={onFileChange2} />
                        </div>
                        <div className="col-lg-6 col-md-4 pt-5">
                            <button type="button" disabled={formData.salaryFile === "" ? true : false} className="btn btn-md btn-primary mt-5 w-50" onClick={onUploadSalaryBulk}>Upload Employee Salary Settings</button>
                        </div>
                    </div>
                }

                <ReportTable title={"Manage Employees"} columns={columns} data={tableData} height={"700px"} />

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ManageEmployees);
