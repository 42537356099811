import React from 'react'
import { ProgressBar } from "react-bootstrap";


export default function RunSalaryForm(props) {
    return (
        <div>
            <form onSubmit={props.onSubmit}>

                <div className="row">

                    <div className="col-md-12 pb-3 mb-5">
                        <div className="form-group">
                            <label htmlFor="month_id">Month</label>
                            <input type="month" id="month_id"
                                className="form-control"
                                value={props.values.month_id}
                                onChange={props.onChange}
                                max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1}`}
                                required />
                        </div>
                    </div>
                    <div className='mb-4'>
                        <ProgressBar now={props.progress.percentage} label={`${props.progress.percentage}%`} variant={props.progress.variant} striped />
                    </div>
                    <br />
                    <div className='mt-5'>
                        {
                            props.values.month_id !== "" &&
                            <button
                                type='button'
                                disabled={props.values.month_id === "" || props.isSubmitting}
                                className="btn btn-primary w-100" onClick={() => { props.onRunSalary() }}>
                                {props.isSubmitting ? <span> <i className='fa fa-spin fa-spinner' /> &nbsp;Please wait...</span> : "RUN"}
                            </button>
                        }

                    </div>
                </div>
            </form>
        </div>
    )
}
