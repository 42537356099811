import React from 'react'
import { Link } from 'react-router-dom';
import GeneralToolbar from '../../../component/include/general-toolbar';
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";


const PageCard = ({ title, link, svg }) => {
    return (
        <div className='card pt-5 ps-5 mb-5 mb-xl-8 bg-dark text-white'>
            <Link to={`${link}`}>
                <div className="d-flex align-items-center mb-9 me-2">
                    <div className="symbol symbol-40px me-3">
                        <div className="symbol-label bg-light">
                            <span className="svg-icon svg-icon-1 svg-icon-dark">
                                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="currentColor" />
                                    <path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="currentColor" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="fs-1 text-white fw-bolder lh-2">{title}</div>
                        <div className="fs-7 text-gray-600 fw-bold">{link}</div>
                    </div>
                </div>
            </Link>
        </div>
    )
}
export default function PayRoll() {


    return (
        <div>
            <GeneralToolbar title="Manage Payroll"
                pages={[{ name: 'Dashboard', link: '/' }, { name: 'Payroll', link: '/' },
                { name: 'Manage Payroll', link: '/human-resources/payroll' }]}

            />
            <div className="row">
                <div className="col-xl-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8 theme-dark-bg-body" style={{ backgroundColor: '#CBD4F4' }}>
                        <div className="card-body d-flex flex-column">
                            <div className="row">
                                {/*<div className="col-6  ">*/}
                                {/*    <PageCard title={"Salary Settings"} link={"/human-resources/salary-settings"} />*/}
                                {/*</div>*/}

                                <div className="col-6  ">
                                    <PageCard title={"Allowances & Deductions"} link={"/human-resources/ledger-accounts"} />
                                </div>

                                <div className="col-6  ">
                                    <PageCard title={"Manage Allowances & Deductions"} link={"/human-resources/manage-allowance-and-deduction"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Run Allowances & Deductions"} link={"/human-resources/run-allowance-and-deduction"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Run Salary"} link={"/human-resources/run-salary"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Print Pay Slip"} link={"/human-resources/salary-report"} />
                                </div>

                                <div className='col-6'>
                                    <PageCard title={"Print Multiple Pay Slip"} link={"/human-resources/multiple-pay-slip"} />
                                </div>
                                {/*<div className='col-12'>*/}
                                {/*    <PageCard title={"Pay Register"} link={"/human-resources/pay-register"} />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
