import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import BootstrapModal from '../../../component/modal/bootstrap-modal';
import ReportTable from '../../../component/table/report-table';
import { API_URL } from '../../../context/connection-string';
import { formatDateAndTime } from '../../../context/function';
import SalaryLedgerAccountForm from './ledger-account-form';
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";

function SalaryLedgerAccount(props) {
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const columns = ["S/N", "A&D ID", "A&D Name", "Description", "A&D Type", "Mapping", "Created By", "Date Created", "Action"];

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        ledger_id: "", account_number: "", description: "", account_type: "", balance_or_income: "",
        inserted_by: props.loginData[0]?.employee_id,
    })

    const getData = async () => {
        await axios.get(`${API_URL}human-resources/employee/list`, login?.token).then((res) => {
            setEmployeeList(res.data);
        }).catch((e) => {
            toast.error(e.response.data)
        })

        await getLedgerAccounts();
    }

    const getLedgerAccounts = async () => {
        await axios.get(`${API_URL}human-resources/ledger-accounts/list`, login?.token)
            .then((res) => {
                if (res.data.length > 0) {
                    let rows = [];
                    res.data.map((x, i) => {
                        rows.push([
                            i + 1,
                            x.ledger_id.toString().padStart(4, "0"),
                            x.account_number,
                            x.description,
                            x.account_type,
                            x.balance_or_income,
                            x.inserted_by_name + ` (${x.inserted_by})`,
                            formatDateAndTime(x.inserted_date, "date"),
                            < button className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        ledger_id: x.ledger_id,
                                        account_number: x.account_number,
                                        description: x.description,
                                        account_type: x.account_type,
                                        balance_or_income: x.balance_or_income,
                                    })
                                    setOpenModal(true);
                                }
                                } >
                                <i className='fa fa-edit' /> &nbsp; Edit
                            </button >
                        ])
                    })
                    setTableData(rows)
                }
                setIsLoading(false);
            }).catch((e) => {
                toast.error("error getting ledgers")
            })
    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })
    }


    const onOpenModal = (status) => {
        setIsSubmitting(false);
        setFormData({
            ...formData,
            ledger_id: "", account_number: "", description: "", account_type: "", balance_or_income: "",
        })
        setOpenModal(status);
    }


    const onSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        toast.info("please wait...")
        if (formData.ledger_id === "") {
            await axios.post(`${API_URL}human-resources/ledger-accounts/add`, formData, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        getLedgerAccounts();
                        toast.success("Allowance / Deduction added successfully");
                        onOpenModal(false);
                    } else if (res.data.message === "exists") {
                        toast.warning("Allowance / Deduction already exists");
                    } else {

                    }
                    setIsSubmitting(false)
                }).catch(() => {
                    setIsSubmitting(false);
                    toast.error(e.response.data)
                })
        } else {
            await axios.patch(`${API_URL}human-resources/ledger-accounts/update`, formData, login?.token)
                .then(async (res) => {
                    if (res.data.message === "success") {
                        getLedgerAccounts();
                        toast.success("Allowance / Deduction  added successfully");
                        onOpenModal(false);
                    }
                    setIsSubmitting(false)
                }).catch((e) => {
                    setIsSubmitting(false)
                    toast.error(e.response.data)
                })
        }
    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Allowances / Deductions"
                    pages={[{ name: 'Allowances and Deduction', link: '/' }, { name: 'Payroll', link: '/' },
                    { name: 'Allowances / Deductions', link: '/human-resources/ledger-accounts' }]}
                    button={<button className="btn btn-primary" onClick={() => { onOpenModal(true); setIsSubmitting(false) }}>Add Allowances / Deductions</button>}
                />
                <BootstrapModal
                    openModal={openModal}
                    closeModal={setOpenModal}
                    title="Allowances / Deductions Settings">
                    <SalaryLedgerAccountForm
                        values={formData}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        isSubmitting={isSubmitting}
                        employeeList={employeeList}
                    />
                </BootstrapModal>
                <div className="row">
                    <PayrollSidebar/>
                    <div className="col-xl-10">
                        <ReportTable title={"Allowances / Deductions"} columns={columns} data={tableData} height={"700px"} />
                    </div>
                </div>

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(SalaryLedgerAccount);
