import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import GeneralToolbar from '../../../component/include/general-toolbar';
import Loader from '../../../component/loader/loader';
import { API_URL } from '../../../context/connection-string';
import { formatDateAndTime, showAlert, showConfirm } from '../../../context/function';
import RunSalaryForm from './run-salary-form';
import { useNavigate } from 'react-router';
import PayrollSidebar from "../../../component/payroll-sidebar/payroll-sidebar";
import ReportTable from "../../../component/table/report-table";

function RunSalary(props) {
    const navigate = useNavigate();
    const login = props.loginData[0];
    const [isLoading, setIsLoading] = useState(true);
    const [staffList, setStaffList] = useState([])
    const [allowanceList, setAllowanceList] = useState([])
    const [salarySetting, setsalarySetting] = useState([])
    const [emolumentSettings, setEmolumentsSettings] = useState([]);
    const [employeeEmoluments, setEmployeeEmoluments] = useState([]);


    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        month_id: "",
        inserted_by: props.loginData[0]?.employee_id,
    })
    const [progress, setProgress] = useState({
        percentage: 0,
        variant: 'danger'
    });

    const getData = async () => {
        await axios.get(`${API_URL}human-resources/employee/list/active`, login?.token).then((res) => {
            setStaffList(res.data)
        })
        await axios.get(`${API_URL}human-resources/payroll/allowances_and_dedection/entries`, login?.token).then((res) => {
            setAllowanceList(res.data)
        })

        await axios.get(`${API_URL}human-resources/salary-settings/list`, login?.token).then((res) => {
            setsalarySetting(res.data)
        })

        await axios.get(`${API_URL}human-resources/emolument/meta-data`, login?.token).then((res) => {
            setEmolumentsSettings(res.data.emolumentSettings)
            setEmployeeEmoluments(res.data.employeeEmoluments)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getData();
    }, [])


    const onChange = (e) => {
        const val = e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: val,
        })

        checkPrevRun(e)
    }

    const checkPrevRun = (e) => {
        setIsSubmitting(true);
        axios.post(`${API_URL}human-resources/payroll/salary/check_run`, { salary_date: e.target.value }, login?.token)
            .then(result => {
                if (result.data.length > 0) {
                    toast.error(`Salary already ran for ${formatDateAndTime(e.target.value, "month_and_year")}`);
                    setFormData({
                        ...formData,
                        month_id: ""
                    })
                    setIsSubmitting(false);
                } else {
                    setIsSubmitting(false);
                }
            })
            .catch(err => {
                console.log('NETWORK ERROR', err)
            })
    }

    const onSubmit = async (e) => {
        try {
        setIsSubmitting(true);
        toast.info("please wait...");

        if (formData.month_id === "") {
            showAlert('FORM VALIDATION ERROR', 'Please select a month to run salary!', 'error');
            return false;
        }
        if (salarySetting.length === 0) {
            showAlert('SALARY SETTING NOT FOUND', 'Please add a salary setting from the salary section first!', 'error');
            return false;
        }

        if (staffList.length === 0) {
            showAlert('RECORD NOT EXIST', 'Staff Records could not be found!', 'error');
            return false;
        } else {
            // Start the salary posting process for each staff
            await Promise.all(
                staffList.map(async (item, index) => {
                    let itemList = [];
                    let allowanceAndDeductionItemList = [];
                    let non_taxable_items = [];
                    let taxable_items = [];
                    let salary_data = [];
                    let employeeEmolumentData = employeeEmoluments.filter(x => x.employee_id.toString() === item.staff_id.toString());
                    let nonTaxableList = employeeEmolumentData.filter(x => parseInt(x.is_taxable) === 0);
                    const taxable = employeeEmolumentData.filter(x => parseInt(x.is_taxable) === 1);
                    const basic_salary = employeeEmolumentData.filter(x => x.category === 'basic').map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0) || 0
                    const transport = employeeEmolumentData.filter(x => x.category === 'transport').map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0) || 0
                    const housing = employeeEmolumentData.filter(x => x.category === 'housing').map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0) || 0
                    const pension_taxable = basic_salary + housing + transport;

                    if (nonTaxableList.length > 0) {
                        nonTaxableList.filter(x => parseInt(x.is_percentage) === 1).map((val) => {
                            if (val.category === "pension") {
                                if (parseFloat(val.amount) > 0) {
                                    non_taxable_items.push({
                                        employee_id: item.employee_id,
                                        item_name: val.item_name,
                                        salary_type: 'Deduction',
                                        amount: (parseFloat(val.amount) / 100) * pension_taxable,
                                        is_printable: 1,
                                        salary_date: formData.month_id,
                                        inserted_by: formData.inserted_by
                                    })
                                }
                            } else {
                                if (parseFloat(val.amount) > 0) {
                                    non_taxable_items.push({
                                        employee_id: item.employee_id,
                                        item_name: val.item_name,
                                        salary_type: 'Deduction',
                                        amount: (parseFloat(val.amount) / 100) * basic_salary,
                                        is_printable: 1,
                                        salary_date: formData.month_id,
                                        inserted_by: formData.inserted_by
                                    })
                                }
                            }

                        })


                        nonTaxableList.filter(x => parseInt(x.is_percentage) === 0).map((val) => {
                            if (parseFloat(val.amount) > 0) {
                                non_taxable_items.push({
                                    employee_id: item.employee_id,
                                    item_name: val.item_name,
                                    salary_type: 'Deduction',
                                    amount: parseFloat(val.amount),
                                    is_printable: 1,
                                    salary_date: formData.month_id,
                                    inserted_by: formData.inserted_by
                                })
                            }

                        })
                    }
                    if (taxable.length > 0) {
                        taxable.map((val) => {
                            if (parseFloat(val.amount) > 0) {
                                taxable_items.push({
                                    employee_id: item.employee_id,
                                    item_name: val.item_name,
                                    salary_type: 'Allowance',
                                    amount: parseFloat(val.amount),
                                    is_printable: 1,
                                    salary_date: formData.month_id,
                                    inserted_by: formData.inserted_by
                                })
                            }

                        })
                    }


                    if (allowanceList.length > 0) {
                        const check_staff_allowance = allowanceList.filter(i => i.employee_id.toString() === item.employee_id.toString() && i.salary_date.toString() === formData.month_id.toString());
                        if (check_staff_allowance.length > 0) {
                            check_staff_allowance.map(it => {
                                if (it.allowance_amount > 0) {
                                    allowanceAndDeductionItemList.push({
                                        employee_id: item.employee_id,
                                        item_name: it.gla_account_name,
                                        salary_type: 'Allowance',
                                        amount: it.allowance_amount,
                                        is_printable: 1,
                                        salary_date: formData.month_id,
                                        inserted_by: formData.inserted_by
                                    },)
                                } else {
                                    allowanceAndDeductionItemList.push({
                                        employee_id: item.employee_id,
                                        item_name: it.gla_account_name,
                                        salary_type: 'Deduction',
                                        amount: it.deduction_amount,
                                        is_printable: 1,
                                        salary_date: formData.month_id,
                                        inserted_by: formData.inserted_by
                                    },)
                                }
                            })
                        }
                    }

                    //Pushed taxable_items and non_taxable_items to itemList for division by 12
                    itemList.push(...taxable_items, ...non_taxable_items)

                    const standard_taxable_total = taxable_items.map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
                    const standard_non_taxable_total = non_taxable_items.map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
                    const non_standard_deduction_total = allowanceAndDeductionItemList.filter(e => e.salary_type === "Deduction").map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
                    const non_standard_allowance_total = allowanceAndDeductionItemList.filter(e => e.salary_type === "Allowance").map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
                    const NHF = itemList.filter(e => e.item_name.includes('NHF')).map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
                    const NHIS = itemList.filter(e => e.item_name.includes('NHIS')).map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
                    const pension = itemList.filter(e => e.item_name.includes('Pension')).map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
                    const non_standard_pension = allowanceAndDeductionItemList.filter(e => e.item_name === 'Pension').map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0);
                    const other_standard_non_taxable_total = non_taxable_items.filter(e => !e.item_name.includes('Pension') && !e.item_name.includes('NHIS') && !e.item_name.includes('NHF')).map(e => parseFloat(e.amount)).reduce((a, b) => a + b, 0) || 0;
                    const employee_contribution = pension / 12;
                    const employer_contribution = ((10 / 100) * pension_taxable) / 12;
                    const pension_total_contribution = employee_contribution + employer_contribution;
                    const gross_income_GI_for_GRA_purpose = standard_taxable_total - standard_non_taxable_total;
                    const one_percent_of_GI = (1 / 100) * gross_income_GI_for_GRA_purpose;
                    const fixed_consolidated_relief_allowance_CRA = one_percent_of_GI < 200000 ? 200000 : one_percent_of_GI;
                    const variable_consolidated_relief_allowance_CRA = (20 / 100) * gross_income_GI_for_GRA_purpose;
                    const total_relief = standard_non_taxable_total + fixed_consolidated_relief_allowance_CRA + variable_consolidated_relief_allowance_CRA;
                    const chargeable_income_CI = standard_taxable_total - total_relief;
                    const is_paye_exempt = standard_taxable_total <= 360000;
                    const one_percent_of_TI = (1 / 100) * standard_taxable_total;
                    let paye_bands = [];
                    let total_paye_bands = 0;
                    let WHT = 0;

                    if (item.employee_type === "Part-time") {
                        total_paye_bands = (5 / 100) * standard_taxable_total;
                        WHT = total_paye_bands;
                    } else {
                        if (!is_paye_exempt) {
                            const amounts_to_cut = [300000, 300000, 500000, 500000, 1600000, 3200000]
                            const percentages = [7, 11, 15, 19, 21, 24];
                            paye_bands = splitAmount(chargeable_income_CI, amounts_to_cut, percentages);
                            total_paye_bands = paye_bands?.map((x) => {
                                return parseFloat(x)
                            }).reduce((a, b) => a + b, 0);
                        }
                    }

                    let total_deduction_TD = total_paye_bands + NHF + pension + NHIS + other_standard_non_taxable_total;
                    let minimum_tax = total_paye_bands < one_percent_of_TI ? total_paye_bands : one_percent_of_TI;
                    let net_pay = (standard_taxable_total - total_deduction_TD);
                    const total_allowances = standard_taxable_total + non_standard_allowance_total;
                    const total_deductions = total_deduction_TD + non_standard_deduction_total;
                    const Gross_pay = ((standard_taxable_total / 12) + non_standard_allowance_total);
                    const Gross_pay_for_reporting = ((standard_taxable_total / 12));
                    const NSITF = item.staff_category === "EXTERNAL" ? 0 : (1 / 100) * Gross_pay_for_reporting;

                    let basic = itemList.filter(e => e.item_name.toLowerCase().includes('basic'))
                    basic.length > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "Basic Salary",
                        salary_type: 'Allowance',
                        amount: basic.length > 0 ? parseFloat(basic[0].amount) / 12 : 0,
                        is_printable: 1,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })
                    basic.length > 0 && itemList.filter(e => !e.item_name.toLowerCase().includes('basic') && parseFloat(e.amount) > 0).map(x => {
                        salary_data.push({
                            employee_id: x.employee_id,
                            item_name: x.item_name,
                            salary_type: x.salary_type,
                            amount: parseFloat(x.amount) / 12,
                            is_printable: x.is_printable,
                            salary_date: x.salary_date,
                            inserted_by: x.inserted_by
                        })
                    })
                    allowanceAndDeductionItemList.length > 0 && allowanceAndDeductionItemList.filter(e => parseFloat(e.amount) > 0).map(x => {
                        salary_data.push({
                            employee_id: x.employee_id,
                            item_name: x.item_name,
                            salary_type: x.salary_type,
                            amount: parseFloat(x.amount),
                            is_printable: x.is_printable,
                            salary_date: x.salary_date,
                            inserted_by: x.inserted_by
                        })
                    })
                    total_paye_bands > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "PAYE",
                        salary_type: 'Deduction',
                        amount: total_paye_bands / 12,
                        is_printable: 1,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })
                    WHT > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "WHT",
                        salary_type: 'Deduction',
                        amount: WHT / 12,
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })
                    total_allowances > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "Gross Pay",
                        salary_type: 'Allowance',
                        amount: ((standard_taxable_total / 12) + non_standard_allowance_total),
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })
                    net_pay > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "Net Pay",
                        salary_type: 'Allowance',
                        amount: (((net_pay / 12) + non_standard_allowance_total) - non_standard_deduction_total),
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })
                    chargeable_income_CI > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "Chargeable Income",
                        salary_type: 'Deduction',
                        amount: chargeable_income_CI / 12,
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })

                    total_deductions > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "Total Deduction",
                        salary_type: 'Deduction',
                        amount: ((total_deduction_TD / 12) + non_standard_deduction_total),
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })

                    non_standard_deduction_total > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "Non-Standard Deductions",
                        salary_type: 'Deduction',
                        amount: non_standard_deduction_total,
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })
                    non_standard_allowance_total > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "Non-Standard Allowances",
                        salary_type: 'Allowance',
                        amount: non_standard_allowance_total,
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })
                    pension_taxable > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "Pensionable Emolument",
                        salary_type: 'Deduction',
                        amount: pension_taxable / 12,
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })

                    NSITF > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "NSITF",
                        salary_type: 'Deduction',
                        amount: NSITF,
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })

                    total_allowances > 0 && salary_data.push({
                        employee_id: item.employee_id,
                        item_name: "GrosPayForReport",
                        salary_type: 'None',
                        amount: ((standard_taxable_total / 12)),
                        is_printable: 0,
                        salary_date: formData.month_id,
                        inserted_by: formData.inserted_by
                    })

                    if ((basic.length > 0 && pension > 0) || salary_data.filter(e => e.item_name === 'Pension' ).length > 0) {
                        const pension_data = {
                            employee_id: item.employee_id,
                            pension_id: item.pension_id,
                            employee_contribution: employee_contribution > 0 ? employee_contribution : non_standard_pension,
                            employer_contribution: employer_contribution,
                            total_contribution: pension_total_contribution,
                            salary_date: formData.month_id,
                            inserted_by: formData.inserted_by
                        }
                        await axios.post(`${API_URL}human-resources/payroll/pension/post`, pension_data, login?.token).then((res) => {
                        })
                    }

                    let sendData = {
                        data: salary_data
                    }

                    await axios.post(`${API_URL}human-resources/payroll/salary/post/salary`, sendData, login?.token)
                        .then(async (res) => {

                        })
                        .catch(err => {
                            console.log('NETWORK ERROR', err)
                        })

                    const percentage = (index + 1) / staffList.length * 100;
                    let variant = "";
                    if (percentage <= 25)
                        variant = 'danger'
                    else if (percentage > 25 && percentage <= 50)
                        variant = 'warning'
                    else if (percentage > 50 && percentage <= 75)
                        variant = 'info'
                    else
                        variant = 'success'

                    setProgress({
                        ...progress,
                        percentage: Math.round(percentage),
                        variant: variant
                    })

                    if (index + 1 === staffList.length) {
                         toast.success("SALARY POSTING IS COMPLETED");
                        setFormData({
                            ...formData,
                            month_id: ""
                        })
                    }
                })
            );
        }
        } catch (error) {
            console.error("Error during salary posting:", error);
            // Handle errors if needed
        } finally {
            setIsSubmitting(false);
        }
    }

    function splitAmount(total_amount, amounts_to_cut, percentages) {
        let totalAmount = total_amount;
        let splits = [];

        for (let i = 0; i < amounts_to_cut.length; i++) {
            const percent = percentages[i];
            const amount = amounts_to_cut[i];

            if (i === amounts_to_cut.length - 1 && totalAmount > 3200000) {
                const splitAmount = (24 / 100) * totalAmount;
                splits.push(splitAmount);
                break;
            } else if (totalAmount > amount) {
                const splitAmount = (percent / 100) * amount;
                splits.push(splitAmount);
                totalAmount -= amount;
            } else {
                const splitAmount = (percent / 100) * totalAmount;
                splits.push(splitAmount);
                break;
            }
        }

        return splits;
    }


    const onRunSalary = () => {
        showConfirm("Warning", `Proceed to run salary for ${formatDateAndTime(formData.month_id, "month_and_year")} ?`, "warning")
            .then((confirm) => {
                if (confirm) {
                    onSubmit()
                }
            })
    }

    return isLoading ? (<Loader />) : (
        <div>
            <>
                <GeneralToolbar title="Run Salary"
                    pages={[{ name: 'Dashboard', link: '/' }, { name: 'Human Resources', link: '/human-resources/payroll' },
                    { name: 'Run Salary', link: '/human-resources/run-salary' }]}
                />
                <div className="row">
                    <PayrollSidebar/>
                    <div className="col-xl-10">
                        <div className=''>
                            <div className='card mt-0'>
                                <div className='card-body mt-10'>
                                    <div className="form-group mb-4 col-md-12 alert alert-primary">
                                        <h2 className='text-primary'>NOTICE! </h2>
                                        <p className='text-primary'>You can only run salary once in every month</p>
                                    </div>
                                    <RunSalaryForm
                                        values={formData}
                                        onChange={onChange}
                                        isSubmitting={isSubmitting}
                                        checkPrevRun={checkPrevRun}
                                        progress={progress}
                                        onRunSalary={onRunSalary}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(RunSalary);
