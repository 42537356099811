import React from "react";
import {formatDateAndTime, ProjectName} from "../../context/function";

export default function GeneralFooter() {
    const currentYear = formatDateAndTime(new Date(), 'year_only')
    return (
        <div className="footer py-4 d-flex flex-lg-columnd-print-none " id="kt_footer">
            {/*begin::Container*/}
            <div className="container-xxl d-flex flex-column flex-md-row align-items-center justify-content-between">
                {/*begin::Copyright*/}
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">{currentYear}&copy;</span>
                    <a href="" target="_blank" rel="noreferrer"  className="text-gray-800 text-hover-primary">{ProjectName}</a>
                </div>
                {/*end::Copyright*/}
            </div>
            {/*end::Container*/}
        </div>
    )
}