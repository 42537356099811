import React from 'react'
import { API_URL } from '../../../context/connection-string'
import {currencyConverter, formatDate, SalaryStepScale} from '../../../context/function';
const NoImage = require("../../../assets/img/no-image.jpg")

export default function EmployeesForm(props) {
    const img = props.values.image_path;
    const emolumentSettings = props.emolumentSettings;
    const employeeEmoluments = props.employeeEmoluments;
    const formData = props.value;
    const setFormData = props.setFormData;

    const onIncomeSettingChange = (e, index, data) => {
        let id = e.target.id;
        let value = e.target.value;
        let item_id = e.target.getAttribute('item_id');

        const new_amount = parseFloat(value);
        if (employeeEmoluments.length > 0) {
            const objIndex = employeeEmoluments.findIndex((x => parseInt(x.settings_item_id) === parseInt(item_id)));
            if (objIndex > -1) {
                employeeEmoluments[objIndex].amount = new_amount;
                props.setEmployeeEmoluments(employeeEmoluments);
                //cause ui update
                props.setEmolumentsSettings([...emolumentSettings])
            }
        }
    }

    return (
        <div className='card'>
            {
                props.values.employee_id !== "" &&
                <>
                    {
                        props.showTop &&
                        <div className="d-flex flex-wrap flex-sm-nowrap">

                            <div className="me-7 mb-4">
                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                    <img src={img !== null ? `${API_URL}public/staff/${img}` : NoImage} alt="image" />
                                    <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px" />
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-2">
                                            <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">
                                                {props.values.full_name}
                                            </a>
                                        </div>
                                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                            <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                <span className="svg-icon svg-icon-4 me-1">
                                                    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor" />
                                                        <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor" />
                                                        <rect x={7} y={6} width={4} height={4} rx={2} fill="currentColor" />
                                                    </svg>
                                                </span>
                                                {
                                                    props.titlesList?.filter(x => x.title_id === props.values.job_title_id)[0]?.title
                                                }</a>

                                            <a href="#" className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                                {/*begin::Svg Icon | path: icons/duotune/communication/com011.svg*/}
                                                <span className="svg-icon svg-icon-4 me-1">
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor" />
                                                        <path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                {props.values.email_address}
                                            </a>
                                        </div>
                                        {/*end::Info*/}
                                    </div>

                                </div>
                                <div className="d-flex flex-wrap flex-stack">
                                    <div className="d-flex flex-column flex-grow-1 pe-8">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1}>{props.values.department_name}</div>
                                                </div>
                                                <div className="fw-semibold fs-6 text-gray-400">Department</div>
                                            </div>
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1}>{currencyConverter(props.values.salary)}</div>
                                                </div>
                                                <div className="fw-semibold fs-6 text-gray-400">Salary</div>
                                            </div>
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-2 fw-bold counted" data-kt-countup="true" data-kt-countup-value={4500} data-kt-countup-prefix="$" data-kt-initialized={1}>{props.values.account_number}</div>
                                                </div>
                                                <div className="fw-semibold fs-6 text-gray-400">{props.values.bank_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>

            }

            <form onSubmit={props.onSubmit}>
                <div className="row">
                    <hr/>
                    <h3>Personal Information</h3>
                    <hr/>
                    <div className="col-md-12 pb-3 mt-3 mb-3">
                        <div className="form-group">
                            <label htmlFor="job_title_id"> Title </label>
                            <select className='form-control' id="job_title_id" onChange={props.onChange} value={props.values.job_title_id} required  >
                                <option value={""}> -select job title- </option>
                                {
                                    props.titlesList?.map((x, i) => {
                                        return (
                                            <option key={i} value={x.title_id}> {x.title} </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="Surname">Surname <span className="text-danger">*</span></label>
                            <input type="text" id="Surname" className="form-control" value={props.values.Surname} onChange={props.onChange} required />
                        </div>
                    </div>
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="OtherNames">Other Names <span className="text-danger">*</span></label>
                            <input type="text" id="OtherNames" className="form-control" value={props.values.OtherNames} onChange={props.onChange} required />
                        </div>
                    </div>
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="staff_id">Staff ID <span className="text-danger">*</span></label>
                            <input type="text" id="staff_id"
                                className="form-control" value={props.values.staff_id} onChange={props.onChange} required />
                        </div>
                    </div>
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="email_address">Email Address <span className="text-danger">*</span></label>
                            <input type="email" id="email_address"
                                className="form-control" value={props.values.email_address} onChange={props.onChange} required />
                        </div>
                    </div>
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="phone_number">Phone Number <span className="text-danger">*</span></label>
                            <input type="tel" id="phone_number" className="form-control" value={props.values.phone_number} onChange={props.onChange} required />
                        </div>
                    </div>
                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="gender"> Gender  <span className="text-danger">*</span></label>
                            <select className='form-control' id="gender" onChange={props.onChange} value={props.values.gender} required  >
                                <option value={""}> -select gender- </option>
                                <option value={"Male"}> Male </option>
                                <option value={"Female"}> Female </option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6 pb-3 mt-5 mb-3">
                        <div className="form-group">
                            <label htmlFor="date_of_birth">Date of Birth <span className="text-danger">*</span></label>
                            <input type="date" id="date_of_birth" className="form-control" value={formatDate(props.values.date_of_birth)} onChange={props.onChange} required />
                        </div>
                    </div>

                    <div className="col-md-6 pb-3">
                        <div className="form-group">
                            <label htmlFor="address">Address </label>

                            <textarea type="text" id="address" className="form-control" value={props.values.address} onChange={props.onChange} ></textarea>
                        </div>
                    </div>

                    <div className="col-md-12 pb-3">
                        <div className="form-group">
                            <label htmlFor="image_path">Image </label>

                            <input type="file" id="image_path" className="form-control" onChange={props.onChange} />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <hr/>
                        <h3>Employment Details</h3>
                        <hr/>

                        <div className="row">
                            <div className="col-md-6 pb-3 mt-5 mb-3">
                                <div className="form-group">
                                    <label htmlFor="hire_date">Employment Date <span className="text-danger">*</span></label>
                                    <input type="date" id="hire_date" className="form-control" value={formatDate(props.values.hire_date)} onChange={props.onChange} required />
                                </div>
                            </div>

                            {/*<div className="col-md-6 pb-3 mt-5 mb-3">*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label htmlFor="is_fte"> Is FTE </label>*/}
                            {/*        <select className='form-control' id="is_fte" onChange={props.onChange} value={props.values.is_fte} required  >*/}
                            {/*            <option value={""}> -select IS FTE- </option>*/}
                            {/*            <option value={"1"}> Yes </option>*/}
                            {/*            <option value={"0"}> No </option>*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="col-md-6 pb-3 mt-5 mb-3">
                                <div className="form-group">
                                    <label htmlFor="division_id">Division <span className="text-danger">*</span></label>
                                    <select className='form-control' id="division_id" onChange={props.onChange} value={props.values.division_id} required  >
                                        <option value={""}> -select Division- </option>
                                        {
                                            props.divisionList?.map((x, i) => {
                                                return (
                                                    <option key={i} value={x.division_id}> {x.division_name} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>


                            <div className="col-md-6 pb-3 mt-5 mb-3">
                                <div className="form-group">
                                    <label htmlFor="department_id">Department <span className="text-danger">*</span></label>
                                    <select className='form-control' id="department_id" onChange={props.onChange} value={props.values.department_id} required  >
                                        <option value={""}> -select department- </option>
                                        {
                                            props.departmentList?.map((x, i) => {
                                                return (
                                                    <option key={i} value={x.department_id}> {x.department_name} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3 mt-5 mb-3">
                                <div className="form-group">
                                    <label htmlFor="department_id">Pay Point Department <span className="text-danger">*</span></label>
                                    <select className='form-control' id="pay_point" onChange={props.onChange} value={props.values.pay_point} required  >
                                        <option value={""}> -select Pay Point Department- </option>
                                        {
                                            props.departmentList?.map((x, i) => {
                                                return (
                                                    <option key={i} value={x.department_id}> {x.department_name} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="status">Designation</label>
                                    <select className='form-control' id="designation_id" onChange={props.onChange} value={props.values.designation_id}>
                                        <option value={""}> -select designation- </option>
                                        {
                                            props.designationList?.map((x, i) => {
                                                return (
                                                    <option key={i} value={x.designation_id}> {x.designation_name} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="status">Staff Category</label>
                                    <select className='form-control' id="staff_category" onChange={props.onChange} value={props.values.staff_category}>
                                        <option value={""}> -select category- </option>
                                        <option value="CONTRACT"> CONTRACT </option>
                                        <option value="TENURE"> TENURE </option>
                                        <option value="CONFIRMED"> CONFIRMED </option>
                                        <option value="REGULAR"> REGULAR </option>
                                        <option value="TEMPORARY"> TEMPORARY </option>
                                        <option value="CONTRACT/PENSIONER"> CONTRACT/PENSIONER </option>
                                        <option value="TENURE/PENSIONER"> TENURE/PENSIONER </option>
                                        <option value="SABBATICAL"> SABBATICAL </option>
                                        <option value="EXTERNAL"> EXTERNAL </option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="salary_rank">Rank/Grade Level</label>
                                    {/*<input type="text" id="salary_rank" className="form-control" value={props.values.salary_rank} onChange={props.onChange} required />*/}
                                    <select className='form-control' id="salary_rank" onChange={props.onChange} value={props.values.salary_rank}>
                                        <option value={""}> - select salary rank/grade level - </option>
                                        {
                                            SalaryStepScale?.map((x, i) => {
                                                return (
                                                    <option key={i} value={x}> {x} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="status">Salary Step</label>
                                    <select className='form-control' id="salary_step" onChange={props.onChange} value={props.values.salary_step}>
                                        <option value={""}> - select salary step - </option>
                                        {
                                            SalaryStepScale?.map((x, i) => {
                                                return (
                                                    <option key={i} value={x}> {x} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="status">Status <span className="text-danger">*</span></label>
                                    <select className='form-control' id="status" onChange={props.onChange} value={props.values.status} required  >
                                        <option value={""}> -select status- </option>
                                        <option value={"Active"}> Active </option>
                                        <option value={"InActive"}> In-Active </option>

                                    </select>
                                </div>
                            </div>

                            {/*<div className="col-md-6 pb-3">*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label htmlFor="salary">Salary</label>*/}
                            {/*        <input type="text" id="salary" className="form-control" value={props.values.salary} onChange={props.onChange} required />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="bank_name">Bank Name <span className="text-danger">*</span></label>
                                    <input type="text" id="bank_name" className="form-control" value={props.values.bank_name} onChange={props.onChange} required />
                                </div>
                            </div>
                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="account_number">Account Number <span className="text-danger">*</span></label>
                                    <input type="text" id="account_number" className="form-control" value={props.values.account_number} onChange={props.onChange} required />
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="bank_code">Bank Code</label>
                                    <input type="text" id="bank_code" className="form-control" value={props.values.bank_code} onChange={props.onChange}/>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="sort_code">Sort Code</label>
                                    <input type="text" id="sort_code" className="form-control" value={props.values.sort_code} onChange={props.onChange}/>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="beneficiary_branch">Beneficiary Branch <span className="text-danger">*</span></label>
                                    <input type="text" id="beneficiary_branch" className="form-control" value={props.values.beneficiary_branch} onChange={props.onChange} required />
                                </div>
                            </div>

                            <div className="col-md-6 pb-3 mt-5 mb-3">
                                <div className="form-group">
                                    <label htmlFor="division_id">Pension</label>
                                    <select className='form-control' id="pension_id" onChange={props.onChange} value={props.values.pension_id}>
                                        <option value={""}> -select pension- </option>
                                        {
                                            props.pensionList?.map((x, i) => {
                                                return (
                                                    <option key={i} value={x.pension_id}> {x.pension_name} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="rsa_pin">RSA PIN</label>
                                    <input type="text" id="rsa_pin" className="form-control" value={props.values.rsa_pin} onChange={props.onChange}/>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="nhf_number">NHF Number</label>
                                    <input type="text" id="nhf_number" className="form-control" value={props.values.nhf_number} onChange={props.onChange}/>
                                </div>
                            </div>

                            <div className="col-md-6 pb-3">
                                <div className="form-group">
                                    <label htmlFor="staff_tin_number">Employee TIN Number</label>
                                    <input type="text" id="staff_tin_number" className="form-control" value={props.values.staff_tin_number} onChange={props.onChange}/>
                                </div>
                            </div>

                        </div>

                        <hr/>
                        <h3>Salary Details</h3>
                        <hr/>

                       <div className="row">
                           <div className="col-md-12 pb-5">
                               <div className="form-group">
                                   <label htmlFor="status">Employee Type <span className="text-danger">*</span></label>
                                   <select className='form-control' id="employee_type" onChange={props.onChange} value={props.values.employee_type} required  >
                                       <option value={""}> -select status- </option>
                                       <option value={"Full-time"}> Full-time </option>
                                       <option value={"Part-time"}> Part-time </option>
                                   </select>
                               </div>
                           </div>
                           <div className="col-md-12 pb-3"><h6 className="bg-black text-light w-25">Income and Emoluments</h6></div>
                           {
                               emolumentSettings.length > 0 &&
                               emolumentSettings.filter(q => parseInt(q.is_taxable) === 1).map((x, i) => {
                                   let amount_value = 0;
                                   if (employeeEmoluments.length > 0){
                                       const amount_data = employeeEmoluments?.filter(j => parseInt(j.settings_item_id) === parseInt(x.item_id))
                                        amount_value = amount_data.length > 0 ? amount_data[0].amount : 0;
                                   }

                                   return (
                                       <div key={i} className="col-md-4 pb-3">
                                           <div className="form-group">
                                               <label htmlFor="status">{x.item_name} => ({x.item_id})</label>
                                               <input
                                                   onChange={(e) => { onIncomeSettingChange(e, i, x) }}
                                                   className='form-control score'
                                                   id={`input${x.item_id}`}
                                                   item_name={x.item_name}
                                                   item_id={x.item_id}
                                                   key={i}
                                                   type="number"
                                                   name="input[]"
                                                   step="0.000000001"
                                                   defaultValue={amount_value}
                                                   placeholder="Amount"

                                               />
                                           </div>
                                       </div>
                                   )
                               })
                           }
                           <div className="row">
                               {
                                   props.values.employee_type === "Full-time" ?
                                       <>
                                           <div className="col-md-12 pb-4 pt-3"><h6 className="bg-black text-light w-25">Non Taxable Income & Tax Exemp Items</h6></div>
                                           {
                                               emolumentSettings.length > 0 &&
                                               emolumentSettings.filter(q => parseInt(q.is_taxable) === 0).map((x, i) => {
                                                   let amount_value = 0;
                                                   if (employeeEmoluments.length > 0){
                                                       const amount_data = employeeEmoluments?.filter(j => parseInt(j.settings_item_id) === parseInt(x.item_id))
                                                       amount_value = amount_data.length > 0 ? amount_data[0].amount : 0;
                                                   }

                                                   return (
                                                       <div key={i} className="col-md-4 pb-3">
                                                           <div className="form-group">
                                                               <label htmlFor="status">{x.item_name} => ({x.item_id})  {x.is_percentage === 1 ? <span className="text-info">(In Percentage %)</span> : '' }</label>
                                                               <input
                                                                   onChange={(e) => { onIncomeSettingChange(e, i, x) }}
                                                                   className='form-control score'
                                                                   id={`input${x.item_id}`}
                                                                   item_name={x.item_name}
                                                                   item_id={x.item_id}
                                                                   key={i}
                                                                   type="number"
                                                                   step="0.000000001"
                                                                   name="input[]"
                                                                   defaultValue={amount_value}
                                                                   placeholder="Amount"
                                                               />
                                                           </div>
                                                       </div>
                                                   )
                                               })
                                           }
                                       </>
                                       : <></>
                               }

                           </div>

                       </div>
                    </div>

                    <button type="submit" className="btn btn-lg btn-block btn-primary" disabled={props.isSubmitting} >
                        {props.isSubmitting ? <span> <i className='fa fa-spin fa-spinner' /> &nbsp;Please wait...</span> : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    )
}
